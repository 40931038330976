import React from 'react';
import Container from "../../../container";
import { MemberType, MemberTypeHead } from '../../../components';

const MembershipLanding = () => {

    return (
        <>
            <Container headerType={'inner'}>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div className='inPgHeadBlueBg'>
                            <div className="widthfix">
                                <MemberTypeHead />
                            </div>
                        </div>
                        <div className="widthfix">
                            <MemberType showFull={true} />
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default MembershipLanding;