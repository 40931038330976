import React from 'react';
import AboutSSCSB from './AboutSSCSB';
import AboutSankardev from './AboutSankardev';
import ExecutiveCommittee from './ExecutiveCommittee';
import PresidentDesk from './PresidentDesk';

const AboutTabContent = ({ activeTab }) => {
    let ActiveComponent;
    switch (activeTab) {
        case 'About SSCSB':
            ActiveComponent = AboutSSCSB;
            break;
        case 'About Sankardev':
            ActiveComponent = AboutSankardev;
            break;
        case 'Executive Committee':
            ActiveComponent = ExecutiveCommittee;
            break;
        case 'President Desk':
            ActiveComponent = PresidentDesk;
            break;
        default:
            ActiveComponent = AboutSSCSB;
    }

    return (
        <div>
            <ActiveComponent />
        </div>
    );
};

export default AboutTabContent;
