import React from "react";
import RendorRoutes from "./routes";

function App() {
    return <RendorRoutes />;
}

export default App;

// import React from 'react';
// import { BrowserRouter as Router } from 'react-router-dom';
// import MonthList from './MonthList';
// import EventCalendar from './EventCalendar';
// import PrivateRoute from './PrivateRoute';
// import RendorRoutes from './routes';

// const RouteLinks = [
//     {
//         id: 1,
//         path: "",
//         Component: MonthList,
//         Session: false,
//     },
//     {
//         id: 2,
//         path: "calendar/:month",
//         Component: EventCalendar,
//         Session: false,
//     },
//     // Add more routes as needed
// ];

// function App() {
//     return (
//         <Router>
//             <RendorRoutes />
//         </Router>
//     );
// }

// export default App;
