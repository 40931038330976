import React from 'react';
import Container from "../../../container";
import { Link } from 'react-router-dom';
import { events } from '../component/eventData.js';

const Component = () => {

    const currentMonth = new Date().getMonth();
    const nextMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const nextMonthYear = nextMonth > 11 ? currentYear + 1 : currentYear;

    const filteredEvents = events.filter(event => {
        const eventMonth = event.date.getMonth();
        const eventYear = event.date.getFullYear();
        return (
            (eventMonth === currentMonth && eventYear === currentYear) ||
            (eventMonth === nextMonth && eventYear === nextMonthYear)
        );
    });

    return (
        <>
            <Container headerType={'inner'}>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div className='inPgHeadBlueBg'>
                            <div className="widthfix">
                                <ul className='breadcrumbLink'>
                                    <li className="breadcrumbLink">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumbLink">
                                        Upcoming Event
                                    </li>
                                </ul>
                                <div className='padBot60'></div>
                            </div>
                        </div>
                        <div className="widthfix">
                            <div className='events'>
                                <div className='eventIcol'>
                                    <div className="pgHeadText">Upcoming Events</div>
                                    <div className='calendar'>
                                        <div className="event-table">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '150px' }}>Date</th>
                                                        <th>Title</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredEvents.map((event) => (
                                                        <tr key={event.title}>
                                                            <td>{event.date.toDateString()}</td>
                                                            <td>{event.title}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;