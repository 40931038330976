import React from 'react';
import Container from "../container";
import { Link } from 'react-router-dom';

const Component = () => {



    return (
        <>
            <Container headerType={'inner'}>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div className='inPgHeadBlueBg'>
                            <div className="widthfix">
                                <ul className='breadcrumbLink'>
                                    <li className="breadcrumbLink">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumbLink">
                                        Terms of Use
                                    </li>
                                </ul>
                                <div className='padBot60'></div>
                            </div>
                        </div>
                        <div className="widthfix">
                            <div className='events'>
                                <div className='eventIcol'>
                                    <div className="pgHeadText">Terms of Use</div>
                                    <div className='contentPad20'>
                                        <div className='pgTxtBold'>Introduction</div>
                                        <div className='pgTxtNormal'>This document is an agreement between the Web Application Owner (hereinafter defined) and you (hereinafter defined) containing the terms of use being the legally binding terms and conditions for your use of the Web Application in the website at www.sscsb.in and the services offered in, by, through or pursuant to the Web Application and/or the website at www.sscsb.in by the Web Application Owner (hereinafter defined), features, Content (hereinafter defined), any materials displayed or performed on or through the Web Application and/or the website at www.sscsb.in, widgets offered by the Web Application Owner (hereinafter defined) or, subject to the terms set out under the “Third Party Applications” section below, from third party Web Application stores (e.g., iTunes® store and Google Apps Marketplace®) authorised by us (hereinafter collectively referred to as the “Application”). <b>Sreemanta Sankardeva Cultural Society, Bangalore</b>, including its subsidiaries and affiliates (“SSCSB”, the “Web Application Owner” or “we” or “us” or “our” as the context may require) provides the information contained on the Web Application to visitors (hereinafter collectively referred to as “you” or “your” as the context may require, which terms shall mean and include any person or entity you claim to represent, associate with or are employed by) subject to the terms and conditions set out herein and any other relevant terms and conditions, policies and notices which may be applicable to a specific section or module of the Web Application and the Privacy Policy, the Copyright Notice and the Frequently Asked Questions (“FAQs”). Please read these terms of use carefully before using the Application.</div>
                                        <div className='pgTxtNormal'>This document is an electronic record in terms of the Information Technology Act, 2000 or any statutory modification or re-enactment thereof for the time being in force. This electronic record is generated by a computer system and does not require any physical or digital signatures. This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing the terms for access or usage of Sreemanta Sankardeva Cultural Society, Bangalore Website via the Web Application. This document meets the stipulations and conditions set out in Section 65B (2) of the Indian Evidence Act, 1872. This document shall be construed a valid contract under The Indian Contract Act, 1872 subject to fulfilment of the provisions therein.</div>
                                        <div className='pgTxtBold'>Acceptance of Terms</div>
                                        <div className='pgTxtNormal'>By registering for and/or using the Web Application in any manner, including but not limited to visiting or browsing the Application, you agree to all of the terms and conditions contained herein (the “Terms of Use”) and all other operating rules, policies and procedures that may be published from time to time on the Web Application by us, each of which is incorporated into the Terms of Use by reference and each of which may be updated by us from time to time without notice to you in accordance with the terms set out under the “Modification of Terms of Use” section below. In addition, some services offered through the Web Application may be subject to additional terms and conditions specified by us from time to time; your use of such services is subject to those additional terms and conditions, which are incorporated into the Terms of Use by reference. In addition, if you are a corporate user, you agree to be bound by all terms, conditions, policies and procedures that may be applicable to you by virtue of being associated with the particular corporate entity which permits you to be a user of the Application. The Terms of Use apply to all users of the Application, including, without limitation, users who are contributors of information, and other materials or services on the Application, individual users of the Application, and users and/or their contacts that interact with each other within the Application.</div>
                                        <div className='pgTxtBold'>Access</div>
                                        <div className='pgTxtNormal'>Subject to the Terms of Use, we may offer to provide you access to the Application, solely for your own use, and not for the use or benefit of any third party.</div>
                                        <div className='pgTxtBold'>Registration and Eligibility</div>
                                        <div className='pgTxtNormal'>You may be required to register with us to browse the Web Application and view the Content (hereinafter defined) only and represent, warrant and covenant that you provide us with accurate and complete registration information (including, but not limited to a user name [the “User Name”], mobile telephone number and/or electronic mail [“Email”] address and a password you will use to access the Application) and to keep your registration information accurate and up-to-date. Failure to do so shall constitute a breach of the Terms of Use, which may result in immediate termination of your account in the Application. We recommend, that you use your own name as your User Name. You shall not:</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">create any account for anyone other than yourself without such person’s permission;</li>
                                            <li className="bp_circleOutline">use a User Name that is the name of another person with the intent to impersonate that person;</li>
                                            <li className="bp_circleOutline">use a User Name or an account in the Web Application that is subject to any rights of a person other than you without appropriate authorisation; and/or</li>
                                            <li className="bp_circleOutline">use a User Name that is a name that is otherwise offensive, vulgar or obscene or otherwise unlawful in accordance with applicable laws for the time being in force.</li>
                                        </ul>
                                        <div className='pgTxtNormal'>We reserve the right to refuse registration of, or cancel a User Name in our sole discretion. You are solely responsible and liable for activity that occurs on your account and shall be responsible for maintaining the confidentiality of your account password. You shall never use another user’s account without such other user’s prior express permission. You will immediately notify us in writing of any unauthorised use of your account or other account related security breach of which you are or become aware.</div>
                                        <div className='pgTxtNormal'>You represent and warrant that if you are an individual, you are of legal age to form a binding contract, or that if you are registering on behalf of an entity, that you are authorised to enter into, and bind the entity to, the Terms of Use and register for the Application. The Web Application is not available to individuals who are younger than 18 (eighteen) years of age or a minimum age prescribed for a particular purpose by applicable law for the time being in force, whichever is lesser. We may, at our sole discretion, refuse to offer the Web Application to any person or entity and change its eligibility criteria at any time. You are solely responsible for ensuring that while accessing and/or using the Application, you are in compliance with all laws, rules and regulations for the time being in force and applicable to you. The right to access and/or use the Web Application is revoked where the Terms of Use or use of the Web Application is prohibited and, in such circumstances, you agree not to access or and/or use the Web Application in any manner.</div>
                                        <div className='pgTxtNormal'>Please be aware that your mobile device carrier’s normal rates and fees, such as text messaging and data charges, shall apply. In the event you change or deactivate your mobile telephone number, you agree that you will update your account information on the Web Application within 48 (forty eight) hours to ensure that your messages are not sent to the person who acquires your discontinued mobile telephone number.</div>
                                        <div className='pgTxtBold'>Content</div>
                                        <div className='pgTxtNormal'>All Content (hereinafter defined), whether publicly posted or privately transmitted, is the sole responsibility of the person who originated such Content (hereinafter defined). We cannot guarantee the authenticity of any Content (hereinafter defined) or data which users may provide about themselves. You acknowledge that all Content (hereinafter defined) accessed by you while using the Web Application is at your own risk and you will be solely responsible and liable for any damage or loss to you or any other party resulting therefrom. For purposes of the Terms of Use, the term “Content” includes, without limitation, any location information, “highlights”, video clips, audio clips, responses, information, data, text, photographs, software, scripts, graphics and interactive features generated, provided, or otherwise made accessible by us on or through the Application. Content added, created, uploaded, submitted, distributed, posted or otherwise obtained through the Web Application by users, including Content that is added to the Web Application in connection with users linking their accounts to third party applications, websites and services, is collectively referred to as the “User Submissions”.</div>
                                        <div className='pgTxtBold'>Ownership of SSCSB Content</div>
                                        <div className='pgTxtNormal'>SSCSB is the sole and exclusive copyright owners of the Web Application and the Content. We also exclusively own the copyrights, trademarks, service marks, logos, trade names, trade dress and other intellectual and proprietary rights throughout the world (the “IP Rights”) associated with the Web Application and the Content, which may be protected by copyright, patent, trademark and other applicable intellectual property and proprietary rights and laws. You acknowledge that the Web Application contains original works and have been developed, compiled, prepared, revised, selected, and arranged by us and others through the Web Application of methods and standards of judgment developed and applied through the expenditure of substantial time, effort, and money and constitutes valuable intellectual property of us and such others. You further acknowledge that the Web Application may contain information which is designated as confidential by us and that you shall not disclose such information without our prior written consent.</div>
                                        <div className='pgTxtNormal'>You agree to protect our proprietary rights and the proprietary rights of all others having rights in the Web Application during and after the term of the agreement pursuant to the Terms of Use and to comply with all reasonable written requests made by us or our suppliers and licensors of content or otherwise to protect their and others’ contractual, statutory and common law rights in the Application. You acknowledge and agree that we (or our licensors) own all legal right, title and interest in and to the Application, including any IP Rights which subsist in the Web Application (whether those rights happen to be registered or not, and wherever in the world those rights may exist). Unless you have agreed otherwise in writing with us, nothing in the Terms of Use gives you a right to use any of IP Rights and other distinctive brand features.</div>
                                        <div className='pgTxtNormal'>You agree not to use any framing techniques to enclose any of our trademark or logo or other proprietary information; or remove, conceal or obliterate any copyright or other proprietary notice or source identifier, including without limitation, the size, colour, location or style of any proprietary mark(s). You cannot modify, reproduce, publicly display or exploit in any form or manner whatsoever any of the Content in whole or in part except as expressly authorised by us. You also agree not to decipher, decompile, disassemble, use sniffers (except Ethereal, tcpdump or HTTPWatch in general) or reverse engineer any of the software comprising or in any way making up part of the Application. </div>
                                        <div className='pgTxtNormal'>To the fullest extent permitted by applicable law, we neither warrant nor represent that your use of materials displayed on the Web Application will not infringe rights of third parties not owned by or affiliated with us. You agree to immediately notify us upon becoming aware of any claim that the Web Application and/or the Content infringe upon any copyright trademark, or other contractual, intellectual, statutory, or common law rights.</div>
                                        <div className='pgTxtNormal'>Subject to the Terms of Use, we grant you a personal, limited, non-exclusive and non-transferable license to access and use the Web Application only as expressly permitted in the Terms of Use. You shall not use the Web Application for any illegal purpose or in any manner inconsistent with the Terms of Use. You may use information made available through the Web Application solely for your personal, non-commercial use. You agree not to use, copy, display, distribute, modify, broadcast, translate, reproduce, reformat, incorporate into advertisements and other works, sell, promote, create derivative works, or in any way exploit or allow others to exploit the Content in whole or in part except as expressly authorised by us. Except as otherwise expressly granted to you in writing, we do not grant you any other express or implied right or license to the Application, the Content or our IP Rights.</div>
                                        <div className='pgTxtNormal'>Any violation by you of the license provisions contained in this section may result in the immediate termination of your right to use the Application, as well as potential liability for copyright and other IP Rights infringement depending on the circumstances. We may seek to institute legal proceedings against you for any infringement at an appropriate forum for seeking all available/possible remedies under applicable laws of the country of violation. </div>
                                        <div className='pgTxtBold'>User Submissions</div>
                                        <div className='pgTxtNormal'>We may use your User Submissions in a number of different ways in connection with the Web Application and our activities as we may determine in our sole discretion, including but not limited to, publicly displaying it, reformatting it, incorporating it into marketing materials, advertisements and other works, creating derivative works from it, promoting it, distributing it, and allowing other users to do the same in connection with their own websites, media platforms, and applications (the “Third Party Media”). By submitting User Submissions on the Web Application or otherwise through the Application, you hereby do and shall grant us a worldwide, non-exclusive, royalty-free, fully paid, sublicensable and transferable license to use, copy, edit, modify, reproduce, distribute, prepare derivative works of, display, perform, and otherwise fully exploit the User Submissions in connection with the Web Application and our (and our successors in interest and permitted assigns) business, including without limitation for promoting and redistributing part or all of the Web Application (and derivative works thereof) in any media formats and through any media channels (including, without limitation, third party applications, websites and feeds). You also hereby do and shall grant each user of the Application, including Third Party Media, a non-exclusive license to access your User Submissions through the Application, and to use, edit, modify, reproduce, distribute, prepare derivative works of, display and perform such User Submissions in connection with their use of the Web Application and Third Party Media. For clarity, the foregoing license grant to us does not affect your other ownership or license rights in your User Submissions, including the right to grant additional licenses to the material in your User Submissions, unless otherwise agreed in writing with us.</div>
                                        <div className='pgTxtNormal'>You represent and warrant that you have all rights to grant such license to us without infringement or violation of any third party rights, including without limitation, any privacy rights, publicity rights, copyrights, contract rights, or any other intellectual property or proprietary rights.</div>
                                        <div className='pgTxtNormal'>You understand that all information publicly posted or privately transmitted through the Web Application is the sole responsibility of the person from which such content originated; that we shall not be liable for any errors or omissions in any such content; and that we cannot guarantee the identity of any other users with whom you may interact in the course of using the Application.</div>
                                        <div className='pgTxtNormal'>When you delete your User Submissions, they will be removed from the Application. However, you understand that any removed User Submissions may persist in backup copies for a reasonable period of time (but following removal will not be shared with others) or may remain with users who have previously accessed or downloaded your User Submissions.</div>
                                        <div className='pgTxtBold'>Rules and Conduct</div>
                                        <div className='pgTxtNormal'>As a condition of use, you promise not to use the Web Application for any purpose that is prohibited by these Terms of Use. You are responsible for all of your activity in connection with the Application. Additionally, you shall abide by all applicable local, state, national and international laws and regulations and, if you represent a business, any advertising, marketing, privacy, or other self-regulatory code(s) applicable to your industry.</div>
                                        <div className='pgTxtNormal'>By way of example, and not as a limitation, you shall not (and shall not permit any third party to) either (a)take any action or (b)upload, download, post, submit or otherwise distribute or facilitate distribution of any Content on or through the Application, including without limitation any User Submission, that:</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">infringes any patent, trademark, trade secret, copyright, right of publicity or other right of any other person or entity or violates any law or contractual duty; </li>
                                            <li className="bp_circleOutline">you know is false, misleading, untruthful or inaccurate;</li>
                                            <li className="bp_circleOutline">is unlawful, threatening, abusive, harassing, defamatory, libellous, deceptive, fraudulent, invasive of another's privacy, tortious, obscene, vulgar, pornographic, offensive, profane, contains or depicts nudity, contains or depicts sexual activity, promotes bigotry, discrimination or violence, or is otherwise inappropriate as determined by us in our sole discretion;</li>
                                            <li className="bp_circleOutline">constitutes unauthorised or unsolicited advertising, junk or bulk Email (“spamming”); </li>
                                            <li className="bp_circleOutline">contains software viruses or any other computer codes, files, worms, logic bombs or programs that are designed or intended to disrupt, disable, damage, limit or interfere with the proper function of any software, hardware, or telecommunications equipment or to damage or obtain unauthorised access to any system, data, password or other information of the Web Application Owner or that of any third party; </li>
                                            <li className="bp_circleOutline">impersonates any person or entity, including any employee or representative of the Web Application Owner;</li>
                                            <li className="bp_circleOutline">includes anyone’s identification documents or sensitive financial information; and/or</li>
                                            <li className="bp_circleOutline">breaches any of the policies and rules incorporated herein.</li>
                                        </ul>
                                        <div className='pgTxtNormal'>If the User Submission includes a photograph, the following guidelines shall apply. We have a zero-tolerance policy against pornography, and will terminate and report to the appropriate authorities any user who publishes or distributes such content.</div>
                                        <div className='pgTxtNormal'>You shall not (directly or indirectly):</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">take any action that imposes or may impose (as determined by us in our sole discretion) an unreasonable or disproportionately large load on our (or our third party providers') infrastructure; </li>
                                            <li className="bp_circleOutline">interfere or attempt to interfere with the proper working of the Web Application or any activities conducted on the Application;</li>
                                            <li className="bp_circleOutline">bypass any measures we may use to prevent or restrict access to the Web Application (or other accounts, computer systems or networks connected to the Application);</li>
                                            <li className="bp_circleOutline">run any form of auto-responder or “spam” on the Application;</li>
                                            <li className="bp_circleOutline">use manual or automated software, devices, or other processes to “crawl” or “spider” any segment of the Application;</li>
                                            <li className="bp_circleOutline">harvest or scrape any Content from the Application;</li>
                                            <li className="bp_circleOutline">modify, adapt, appropriate, reproduce, distribute, translate, create derivative works or adaptations of, publicly display, republish, repurpose, sell, trade, or in any way exploit the Web Application and/or the Content (other than your User Submissions as permitted in the Terms of Use), except as expressly authorised by us;</li>
                                            <li className="bp_circleOutline">decipher, decompile, disassemble, reverse engineer or otherwise attempt to derive any source code or underlying ideas or algorithms of any part of the Application, except to the limited extent applicable laws for the time being in force specifically prohibit such restriction;</li>
                                            <li className="bp_circleOutline">copy, rent, lease, distribute, or otherwise transfer any of the rights that you receive hereunder; and/or</li>
                                            <li className="bp_circleOutline">otherwise take any action in violation of our policies.</li>
                                        </ul>
                                        <div className='pgTxtNormal'>We do not guarantee that any Content or User Submissions will be made available on the Application. We have no obligation to monitor the Application, the Content, or the User Submissions. However, we reserve the right to</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">remove, suspend, edit or modify any Content in our sole discretion, including without limitation any User Submissions at any time, without notice to you and for any reason (including, but not limited to, upon receipt of claims or allegations from third parties or authorities relating to such Content or if we are concerned that you may have violated the Terms of Use), or without any reason whatsoever; and</li>
                                            <li className="bp_circleOutline">to remove, suspend or block any User Submissions from the Application.</li>
                                        </ul>
                                        <div className='pgTxtNormal'>We also reserve the right to access, read, preserve, and disclose any information as we reasonably believe is necessary to:</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">satisfy any applicable law for the time being in force, regulation, legal process or governmental request;</li>
                                            <li className="bp_circleOutline">enforce the Terms of Use, including investigation of potential violations thereof;</li>
                                            <li className="bp_circleOutline">detect, prevent, or otherwise address fraud, security or technical issues;</li>
                                            <li className="bp_circleOutline">respond to user support requests; and/or</li>
                                            <li className="bp_circleOutline">protect the rights, property or safety of the Web Application Owner, its users and the public.</li>
                                        </ul>
                                        <div className='pgTxtNormal'>If you see anything inappropriate, please  alert us by sending an Email to <Link to="mailto:sscsb.support@gmail.com">sscsb.support@gmail.com.</Link>. </div>
                                        <div className='pgTxtBold'>Third Party Sites and Services</div>
                                        <div className='pgTxtNormal'>The Web Application may permit you to link to other applications, websites, services or resources on the Internet (the “Third Party Applications”) and Third Party Applications may contain links to the Application. When you access Third Party Applications, you do so at your own risk. Third Party Applications are not under our control, and you acknowledge that we are not responsible or liable for the content, functions, accuracy, legality, appropriateness, use of information in relation to you, personal or otherwise or any other aspect of Third Party Applications. The inclusion of any such link does not imply endorsement by us or any association with its operators. You further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any content, goods or services available on or through any Third Party Applications. You further acknowledge and agree that when you choose, at your sole discretion, the experiences listed in the Web Application which are provided by third parties at venues owned, operated, rented or leased by the third parties, we merely inform you of these experiences through the Web Application and facilitate your access to these experiences as the actual physical access to and the execution of these experiences are not under our control and we are not responsible or liable, in any manner whatsoever, for your actual physical access to the experiences and the execution of these experiences.</div>
                                        <div className='pgTxtBold'>Payment </div>
                                        <div className='pgTxtNormal'>If you subscribe to any of our paid Services, you agree to pay us the applicable fees and taxes and to additional terms specific to the paid services as may be intimated from time to time. Failure to pay these fees will result in the termination of your paid services. Also, you agree that:</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">Your purchase may be subject to foreign exchange fees or differences in prices based on location (e.g. exchange rates);</li>
                                            <li className="bp_circleOutline">We may store and continue billing your payment method (e.g. credit card) even after it has expired, to avoid interruptions in your services and to use to pay other services you may subscribe to;</li>
                                            <li className="bp_circleOutline">If you purchase a subscription, your payment method automatically will be charged at the start of each subscription period for the fees and taxes applicable to that period. To avoid future charges, please cancel before the renewal date (refer the provisions pertaining to ‘Termination’);</li>
                                            <li className="bp_circleOutline">All of your subscription of services are subject to our refund policy as may be intimated from time to time; and</li>
                                            <li className="bp_circleOutline">We may calculate taxes payable by you based on the billing information that you provide us at the time of purchase.</li>
                                        </ul>
                                        <div className='pgTxtNormal'>You can get or view a copy of your invoice through your SSCSB Membership account profile section.</div>
                                        <div className='pgTxtBold'>Termination</div>
                                        <div className='pgTxtNormal'>We may terminate your access to all or any part of the Web Application at any time, with or without cause, with or without notice, effective immediately, which may result in the forfeiture and destruction of all information associated with your membership. If you wish to terminate your account, you may do so by contacting us at <Link to="mailto:sscsb.support@gmail.com">sscsb.support@gmail.com.</Link>.  Any fees paid by you are non-refundable. All provisions of the Terms of Use which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</div>
                                        <div className='pgTxtBold'>Warranty Disclaimer</div>
                                        <div className='pgTxtNormal'>Save to the extent required by applicable law for the time being in force, we have no special relationship with or fiduciary duty to you. You acknowledge that we have no control over, and no duty to take any action regarding: which users gain access to the Application; what Content you access via the Application; what effects the Content may have on you; how you may interpret or use the Content; or what actions you may take as a result of having been exposed to the Content.</div>
                                        <div className='pgTxtNormal'>You release us from all liability for you having acquired or not acquired Content through the Application. The Web Application may contain, or direct you to applications and/or websites containing, information that some people may find offensive or inappropriate. We make no representations concerning any Content contained in or accessed through the Application, and we shall not be responsible or liable for the accuracy, copyright compliance, legality or decency of material contained in or accessed through the Application.</div>
                                        <div className='pgTxtNormal'>You release us from all liability relating to your connections and relationships with other users. You understand that we do not, in any way, screen users, nor do we inquire into the backgrounds of users or attempt to verify their backgrounds or statements. We make no representations or warranties as to the conduct of users or the veracity of any information users provide. In no event shall we be liable for any damages whatsoever, whether direct, indirect, general, special, compensatory, consequential, and/or incidental, arising out of or relating to your conduct or the conduct of anyone else in connection with the Application, including, without limitation, bodily injury, emotional distress, and any and all damages resulting in any way from communications or meetings with users or persons you may otherwise meet through the Web Application or from attending an experience through the Application. As such, you agree to take reasonable precautions and exercise the utmost personal care in all interactions with any individual you come into contact with through the Application, particularly if you decide to meet such individuals in person. For example, you should not, under any circumstances, provide your financial information (e.g., credit card or bank account numbers) to other individuals.</div>
                                        <div className='pgTxtNormal'>The Web Application and the Content are provided “As Is”, “As Available” and are provided without any representations or warranties of any kind, express or implied, including, but not limited to, the implied warranties of title, non-infringement, merchantability and fitness for a particular purpose and any warranties implied by any course of performance or usage of trade, all of which are expressly disclaimed save to the extent required by applicable law for the time being in force.</div>
                                        <div className='pgTxtNormal'>We, our team, officials, employees, agents, representatives, suppliers, service providers, partners and content providers do not warrant that:</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline"> the Web Application will be secure or available at any particular time or location; </li>
                                            <li className="bp_circleOutline">any defects or errors will be corrected;</li>
                                            <li className="bp_circleOutline">any Content or software available at or through the Web Application is free of viruses or other harmful components; and/or</li>
                                            <li className="bp_circleOutline">the results of using the Web Application will meet your requirements. Your use of the Web Application is solely at your own risk. Certain jurisdictions do not allow limitations on implied warranties and to that extent the aforementioned limitations may not apply to you.</li>
                                        </ul>
                                        <div className='pgTxtBold'>Additional Disclaimers</div>
                                        <div className='pgTxtNormal'>The Web Application Owner is a <b>registered Socio-Cultural not profitable organization established in January, 2016</b> Under the Karnataka Societies Registration Act, 1960 that offers a platform to connect with the Assamese Community, offer various services at the Naamghar (Assamese Prayer House), register and participate in various offline cultural activities, various philanthropic and charitable activities of SSCSB, among others, through its Web Application using hardware and software that can function across the country on air. </div>
                                        <div className='pgTxtNormal'>The Web Application Owner has developed a technology enabled Web Application platform in the website www.sscsb.in that helps in connecting users and members with ease online and on phone call taking note of name, services and contact details purely on good faith, to increase socio-cultural enrichment of the Assamese community. </div>
                                        <div className='pgTxtNormal'>The registration with and/or use of the Web Application by users shall not be construed:</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">to give the users the right or power to direct or control the daily activities of SSCSB;</li>
                                            <li className="bp_circleOutline">to constitute SSCSB as an agent, partner, franchisee, or joint venture partner of the users; or </li>
                                            <li className="bp_circleOutline">to permit the users: </li>
                                            <li className="bp_indent_30">to create or assume any obligations or liabilities on behalf of SSCSB for any purpose whatsoever; or </li>
                                            <li className="bp_indent_30">to represent to any person, firm or entity that the users have any right or power to enter into any binding obligation or liability on behalf of SSCSB except as specifically provided herein. </li>
                                        </ul>
                                        <div className='pgTxtNormal'>The users represent and warrant as follows:</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">The users, as may be applicable, are duly organized and validly existing under applicable law for the time being in force;</li>
                                            <li className="bp_circleOutline">The users have full power, right and authority to enter into these Terms of Use; and </li>
                                            <li className="bp_circleOutline">The users are in full compliance, and shall comply, with all laws, rules and regulations of the jurisdiction of their operations, citizenship and visit (short term or long term). </li>
                                        </ul>
                                        <div className='pgTxtNormal'>The users shall be solely responsible for paying any taxes, direct or indirect, state or local whether payable in India or elsewhere, which may result from any payments made by the user either directly or through the Web Application pursuant to use of the Web Application (“Taxes”).</div>
                                        <div className='pgTxtBold'>Membership</div>
                                        <div className='pgTxtNormal'>The purpose of <Link to="https://www.www.sscsb.in">www.sscsb.in</Link> is to provide a communication and collaboration platform for users of the website, SSCSB members, and devotees of the Naamghar. We reserve the sole and absolute right to determine your eligibility to join as a member, and to grant or refuse you admission to the membership on these terms. Membership is also subject to acceptance of all the terms associated with various membership types. </div>
                                        <div className='pgTxtBold'>Indemnification</div>
                                        <div className='pgTxtNormal'>You shall defend, indemnify, and hold harmless the Web Application Owner and each of its officials, employees, agents, representatives, suppliers, service providers, partners and content providers from any and all losses, costs, actions, claims, damages, expenses (including reasonable legal costs) or liabilities, that arise from or relate to your use or misuse of, or access to, the Application, the Content or otherwise from your User Submissions, violation of the Terms of Use, or infringement by you, or any third party using the your account, of any intellectual property or other rights of any person or entity (save to the extent that a court of competent jurisdiction holds that such claim arose due to an act or omission of the Web Application Owner), or your breach of the terms of employment, or your non-payment of Taxes. We reserve the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will assist and cooperate with us in asserting any available defenses.</div>
                                        <div className='pgTxtBold'>Limitation of Liability</div>
                                        <div className='pgTxtNormal'>Any and all liability of the Web Application Owner, its officials, employees, agents, representatives, suppliers, service providers, partners and content providers howsoever arising for any loss suffered as a result of your use of the Application, the Content and/or the User Submissions is expressly excluded to the fullest extent permitted by applicable law for the time being in force, save that, if a court of competent jurisdiction determines that liability of the Web Application Owner, its officials, employees, agents, representatives, suppliers, service providers, partners and content providers (as may be applicable) has arisen, the total of such liability shall be limited in aggregate to INR 1,000 (Indian Rupees One Thousand).</div>
                                        <div className='pgTxtNormal'>To the maximum extent permitted by applicable law for the time being in force, in no event shall the Web Application Owner, its officials, employees, agents, representatives, suppliers, service providers, partners and content providers be liable under contract, tort, strict liability, negligence or any other legal or equitable theory or otherwise (and whether or not the Web Application Owner, its officials, employees, agents, representatives, suppliers, service providers, partners and content providers had prior knowledge of the circumstances giving rise to such loss or damage) with respect to the Application, the Content and/or User Submissions for:</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">indirect or consequential losses or damages;</li>
                                            <li className="bp_circleOutline">loss of actual or anticipated profits;</li>
                                            <li className="bp_circleOutline">loss of revenue;</li>
                                            <li className="bp_circleOutline">loss of goodwill;</li>
                                            <li className="bp_circleOutline">loss of data;</li>
                                            <li className="bp_circleOutline">loss of anticipated savings;</li>
                                            <li className="bp_circleOutline">wasted expenditure; and/or</li>
                                            <li className="bp_circleOutline">cost of procurement of substitute goods or services.</li>
                                        </ul>
                                        <div className='pgTxtNormal'>Nothing in the Terms of Use shall be deemed to exclude or limit your liability in respect of any indemnity provided by you under the Terms of Use. Applicable Law for the time being in force may not allow the limitation or exclusion of liability or incidental or consequential damages, so the aforementioned limitation or exclusion may not apply to you. In such cases, our liability shall be limited to the fullest extent permitted by applicable law for the time being in force.</div>
                                        <div className='pgTxtNormal'>In no event shall we be liable for any damages whatsoever, whether direct, indirect, general, special, compensatory, consequential, and/or incidental, arising out of or relating to your attending an experience through the Application.</div>
                                        <div className='pgTxtBold'>Governing Law and Dispute Resolution</div>
                                        <div className='pgTxtNormal'>A printed version of the Terms of Use and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to the Terms of Use to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. </div>
                                        <div className='pgTxtNormal'>The Terms of Use and all other policies available on the Web Application shall be interpreted and construed in accordance with the laws of India. The Courts of appropriate jurisdiction at Bangalore, Karnataka, India shall have exclusive jurisdiction over any disputes arising under the agreement contained in the Terms of Use.</div>
                                        <div className='pgTxtNormal'>You agree that you will commence any cause of action arising out of or related to the Web Application within 1 (one) year after the cause of action arose. Failure on your part to commence action within the stipulated period shall forever bar any claims or causes of action regarding the same facts or occurrence, notwithstanding any statute of limitation or other law to the contrary. Any failure by us to enforce or exercise any provision of the Terms of Use within 1 (one) year after a cause of action arose shall not constitute a waiver of that right or provision.</div>
                                        <div className='pgTxtBold'>Entire Understanding, Severability and Effects of Waiver</div>
                                        <div className='pgTxtNormal'>The Terms of Use, the Privacy Policy and the Copyright Notice constitute the entire agreement between you and the Web Application Owner in relation to your use of the Web Application and/or the User Submissions, and supersede all prior or contemporaneous communications and proposals (whether oral, written or electronic) between you and the Web Application Owner with respect to the Application. </div>
                                        <div className='pgTxtNormal'>If any provision of the Terms of Use is found to be shall be invalid or unenforceable to any extent for any reason including by reason of applicable law for the time being in force, the remainder of the Terms of Use and the Web Application of such provision to persons or circumstances other than those as to which it is held invalid or unenforceable shall not be affected thereby, and each provision of the Terms of Use shall be valid and enforceable to the fullest extent permitted by applicable law for the time being in force. Any invalid or unenforceable provision of the Terms of Use shall be replaced with a provision, which is valid and enforceable and most nearly reflects the original intent of the invalid and unenforceable provision. </div>
                                        <div className='pgTxtNormal'>No forbearance, indulgence or relaxation or inaction by the Web Application Owner at any time to require performance of any of the provisions of the Terms of Use shall in any way affect, diminish or prejudice the right of the Web Application Owner to require performance of that provision. Any waiver or acquiescence by the Web Application Owner of any breach of any of the provisions of the Terms of Use shall not be construed as a waiver or acquiescence of any right under or arising out of the Terms of Use, or of the subsequent breach, or acquiescence to or recognition of rights other than as expressly stipulated in the Terms of Use. No waiver of any breach of any provision of the Terms of Use shall be effective or binding unless made in writing and signed by the Web Application Owner and, unless otherwise provided in the written waiver, shall be limited to the specific breach waived.</div>
                                        <div className='pgTxtBold'>Modification of the Terms of Use</div>
                                        <div className='pgTxtNormal'>We reserve the right, at our sole discretion, to modify or replace any of the Terms of Use, or change, suspend, or discontinue the Web Application (including without limitation, the availability of any feature, database, or content) at any time by posting a notice on the Web Application or by sending you notice through the Web Application or via Email. We may also impose limits on certain features and services or restrict your access to parts or all of the Web Application without notice or liability. It is your responsibility to check the Terms of Use periodically for changes. Your continued use of the Web Application following the posting of any changes to the Terms of Use constitutes acceptance of those changes.</div>
                                        <div className='pgTxtBold'>Miscellaneous</div>
                                        <div className='pgTxtNormal'>We shall not be liable for any failure to perform our obligations hereunder where such failure results from any cause beyond our reasonable control, including, without limitation, mechanical, electronic or communications failure or degradation (including “line-noise” interference). </div>
                                        <div className='pgTxtNormal'>The Terms of Use are personal to you, and are not assignable, transferable or sublicensable by you except with our prior written consent. We may assign, transfer or delegate any of our rights and obligations hereunder without your consent. </div>
                                        <div className='pgTxtNormal'>No agency, partnership, joint venture, or employment relationship is created as a result of the Terms of Use and neither party has any authority of any kind to bind the other in any respect.</div>
                                        <div className='pgTxtNormal'>Unless otherwise specified in the Term of Use, all notices, statements or other communication required or permitted to be given or made under the Terms of Use shall be in writing in the English language and delivered by Email addressed to the intended recipient. All notices shall be deemed to have been validly given on the business day immediately after the date of confirmation of transmission recorded on the sender's computer.</div>
                                        <div className='pgTxtBold'>Contact</div>
                                        <div className='pgTxtNormal'>You may Email us at: <Link to="mailto:sscsb.support@gmail.com">sscsb.support@gmail.com.</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;