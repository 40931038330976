import React from 'react';
import { Link } from 'react-router-dom';

// Offer Xorai
const mnu1 = [
    "#"
];
// Events
const mnu2 = [
    "#"
];
// Gallery
const mnu3 = [
    "#"
];
// More
const mnu4 = [
    "#",
];

const Navbar = (props) => {
    const { loc } = props;

    const IsActive = (mnu) => {
        return mnu.findIndex((x) => x === loc) > -1 ? "dropbtn active" : "dropbtn";
    };

    return (

        <div>
            <div className="dropdown">
                <Link to="/xorai"><button className={IsActive(mnu1)}>
                    Offer Xorai
                </button></Link>
            </div>

            <div className="dropdown">
                <Link to="#"><button className={IsActive(mnu2)}>
                    Events <div className="arrow_down_topnav"></div>
                </button></Link>
                <div className="dropdown-content">
                    <Link to="/eventcalendar">Event Calendar</Link>
                    <Link to="/eventupcoming">Upcoming Events</Link>
                </div>
            </div>

            <div className="dropdown">
                <Link to="#"><button className={IsActive(mnu3)}>
                    Gallery <div className="arrow_down_topnav"></div>
                </button></Link>
                <div className="dropdown-content">
                    <Link to="/galleryphotoevents">Photo Gallery</Link>
                    {/* <Link to="/galleryvideoevents">Video Gallery</Link> */}
                </div>
            </div>

            <div className="dropdown">
                <Link to="#"><button className={IsActive(mnu4)}>
                    More <div className="line_topnav"></div>
                </button></Link>
                <div className="dropdown-content">
                    <Link to="/bhokot">Bhokot List</Link>
                    {/* <Link to="/library">Library</Link>
                    <Link to="/publication">Publications</Link> */}
                    <Link to="/membership">Membership</Link>
                    <Link to="/memberlist">Members List</Link>
                    <Link to="/about">About SSCSB</Link>
                    <Link to="/contact">Contact Us</Link>
                    <Link to="/privacy">Privacy Policy</Link>
                    <Link to="/terms">Terms Of Use</Link>
                    <Link to="/refund">Refund Policy</Link>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
