import React from 'react';
import Container from "../container";
import { Link } from 'react-router-dom';

const Component = () => {



    return (
        <>
            <Container headerType={'inner'}>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div className='inPgHeadBlueBg'>
                            <div className="widthfix">
                                <ul className='breadcrumbLink'>
                                    <li className="breadcrumbLink">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumbLink">
                                        Refund Policy
                                    </li>
                                </ul>
                                <div className='padBot60'></div>
                            </div>
                        </div>
                        <div className="widthfix">
                            <div className='events'>
                                <div className='eventIcol'>
                                    <div className="pgHeadText">Cancellation and Refund Policy</div>
                                    <div className='contentPad20'>
                                        <div className='pgTxtBold'>Acceptance</div>
                                        <div className='pgTxtNormal'>As a user of the <b>Sreemanta Sankardeva Cultural Society, Bangalore's (hereinafter defined as SSCSB)</b> Website and/ or Web Application <b>(hereinafter defined as Website)</b>, you understand that the Services like Donations, Events, Offerings, Sponsorships, Subscriptions or Memberships may result in charges to you for the services or goods you order ("Charges").</div>
                                        <div className='pgTxtNormal'>The charges for the services are paid by the you in Advance at the time of Booking the Service. You receive a Booking Confirmation from SSCSB's Website at the time of booking the Service and successfully completing the payment to SSCSB's Website for the services booked.</div>
                                        <div className='pgTxtNormal'>Payment of the Charges in such manner shall be considered as final and non-refundable, unless otherwise determined by SSCSB.</div>
                                        <div className='pgTxtBold'>Cancelation</div>
                                        <div className='pgTxtNormal'>Cancelation Request can be made by an email to <Link to="mailto:sscsb.support@gmail.com">sscsb.support@gmail.com.</Link>. If the Service Request has been activated, the cancelation request will not be entertained and the amount paid is Non-Refundable. On successful cancelation of a Service Request, a Cancelation Confirmation email will be sent to the users email id.</div>
                                        <div className='pgTxtBold'>Refund</div>
                                        <div className='pgTxtNormal'>While processing refund, full refund will be processed within 10 working days of the refund request. Full refund will be processed for cancelation of <b>lifetime membership</b> request if made <b>within 5 days</b> of the transaction. The full refund will be processed <b>within 10 working days</b> of the cancelation request.</div>
                                        <div className='pgTxtNormal'>There is no refunds for donation cancelation requests or any other subscriptions, memberships, offerings, event contributions, etc. other than lifetime membership. </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;