import { React, Slider, Link } from "../../../common";
import Mp4Video from "../video/clouds.mp4";
import WebmVideo from "../video/clouds.webm";
import { DonatePop } from "../../components";

const SliderComponent = ({ children }) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
  };

  return <Slider {...settings}>{children} </Slider>
}

const Component = () => {
  const [isOpen2, setIsOpen2] = React.useState(false);

  const togglePopup2 = () => {
    setIsOpen2(!isOpen2);
  };

  return (
    <>
      <div id="homeVideo">
        <div className="videoContainer">
          <ul className="videoBG">
            <li className="videoBG"></li>
          </ul>
          <video className="video1-bg" autoPlay={true} loop muted style={{ width: '100%' }}>
            <source src={Mp4Video} />
            <source src={WebmVideo} />
            Video not supported.
          </video>
        </div>
        <div className="fallback_smallscreen"></div>

        <div className="videoContent_blk">
          <div className="widthfix">
            <ul className="videoContent">
              <li className="videoContent">
                <div className="welcome">
                  Welcome to <br />BANGALORE NAAMGHAR
                </div>
                <div className="xoraiIcon"></div>


                <div className="widthfix_corousel">
                  <SliderComponent>
                    {/* First Slide - Offer Xorai */}
                    {/* <div className="widthfix_corousel_content">
                      <div className="vc_line1">Upcoming Event:</div>
                      <div className="vc_line2">Join us in celebrating Pratistha Diwas on June 21</div>
                      <div className="vc_line3">The Assamese Namghar in Bangalore will celebrate its Pratistha Diwas on June 21. Join us for the consecration ceremony and be part of this sacred event</div>
                      <div className="vcButtonBlk">
                        <Link to="/eventcalendar"><button class="vcButton">View Events</button></Link>
                      </div>
                    </div> */}

                    <div className="widthfix_corousel_content">
                      <div className="vc_line1">Upcoming Event:</div>
                      <div className="vc_line2">Join us in celebrating Janmastami on 25 & 26 Aug</div>
                      <div className="vc_line3">Janmastami celebrates Lord Krishna's birth with vibrant festivities, including dancing, singing, fasting, and devotional rituals, uniting communities in joy.</div>
                      <div className="vcButtonBlk">
                        <Link to="/eventcalendar"><button class="vcButton">View Events</button></Link>
                      </div>
                    </div>

                    <div className="widthfix_corousel_content">
                      <div className="vc_line1">Discover Peace:</div>
                      <div className="vc_line2">Offer Xorai in the Sacred Space of Naamghar</div>
                      <div className="vc_line3">A special ritual that brings peace and connection. <br />Feel the calmness as you connect with something greater than yourself.</div>
                      <div className="vcButtonBlk">
                        <Link to="/xorai"><button class="vcButton">Simple Process</button></Link>
                      </div>
                    </div>

                    <div className="widthfix_corousel_content">
                      <div className="vc_line1">Join Through:</div>
                      <div className="vc_line2">SSCS Bangalore's Official WhatsApp Channel</div>
                      <div className="vc_line3">Keep your calendar alive with exciting updates on forthcoming events, vibrant festivals, enlightening spiritual insights and beyond.</div>
                      <div className="vcButtonBlk">
                        <button class="vcButton">Join Now</button>
                      </div>
                    </div>

                    <div className="widthfix_corousel_content">
                      <div className="vc_line1">Appeal for Donation:</div>
                      <div className="vc_line2">Construction of Naamghar Boundary Wall</div>
                      <div className="vc_line3">Join us in preserving our sacred space — Donate today to help construct the boundary wall of our beloved Naamghar. Your contribution can really help.</div>
                      <div className="vcButtonBlk">
                        <Link to="#" ><button class="vcButton" onClick={togglePopup2}>Donate Now</button></Link>
                      </div>
                    </div>


                  </SliderComponent>
                </div>
                <DonatePop isOpen={isOpen2} onClose={togglePopup2} />
              </li>
              <li className="videoContent">
                <div className="joinCommunity">
                  <div className="joinHeadBlk">
                    <h1>Join the Community</h1>
                    <h2>Keeps you notified on fortcoming events, vibrant festivals and beyond</h2>
                  </div>
                  <div className="joinFormContainer">
                    <form>
                      <div className="userIcon"></div>
                      <input
                        type="text"
                        id="name"
                        placeholder="Name"
                      />
                      <ul className="joinFormContainerIIcol">
                        <li className="joinFormContainerIIcol">
                          <div className="phIcon"></div>
                          <div className="getOtpBtnBlk">
                            <input type="button" className="getOtpBtn" value="Get OTP" />
                          </div>
                          <input
                            type="text"
                            id="phone"
                            placeholder="Phone"
                            className="no-spinner"
                          />
                        </li>
                        <li className="joinFormContainerIIcol">
                          <div className="otpIcon"></div>
                          <input
                            type="text"
                            id="otp"
                            placeholder="OTP"
                            className="no-spinner"
                          />
                        </li>
                      </ul>
                      <div className="termsAgree">By clicking on Join Now you agree to our <Link to="/terms">Terms of Use</Link></div>
                      <div className='buttonLabel'>
                        <input type="button" className="joinButton" value="Join Now" />
                      </div>
                    </form>
                  </div>
                  <div className="socialMedia">
                    <div className="socialMediaIcons">
                      <Link to="https://www.instagram.com/sscsbaxomianaamghar/" target="_blank" rel="noopener noreferrer">
                        <i className="instaIcon"></i>
                      </Link>
                      <Link to="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                        <i className="twitterIcon"></i>
                      </Link>
                      <Link to="https://www.facebook.com/sscsbangalore" target="_blank" rel="noopener noreferrer">
                        <i className="facebookIcon"></i>
                      </Link>
                      <Link to="https://www.youtube.com/channel/UCcQVRxczild0DUTpfcZFBWg/videos?themeRefresh=1" target="_blank" rel="noopener noreferrer">
                        <i className="youtubeIcon"></i>
                      </Link>
                      <Link to="https://www.youtube.com/channel/UCcQVRxczild0DUTpfcZFBWg/videos?themeRefresh=1" target="_blank" rel="noopener noreferrer">
                        <i className="whatsAppIcon"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div >
    </>
  );
};

export default Component;