const BhokotData = [
    {
        photo: '../content/img/smallscreen.jpg',
        name: 'Suresh Barua',
        contact: '9880833913'
    },
    {
        // photo: 'path/to/photo2.jpg',
        name: 'Minaram Saikia',
        contact: '8822805431'
    },
    {
        photo: 'path/to/photo3.jpg',
        name: 'Anal Bhagawati',
        contact: '9435148961'
    },
    {
        photo: 'path/to/photo4.jpg',
        name: 'Phuleshwar Bora',
        contact: '9845546589'
    },
    {
        photo: 'path/to/photo5.jpg',
        name: 'Ganesh Gogoi',
        contact: '7625069994'
    },
    {
        photo: 'path/to/photo6.jpg',
        name: 'Lankeswar Nath',
        contact: '9035417693'
    },
    {
        photo: 'path/to/photo6.jpg',
        name: 'Paresh Bora',
        contact: '9880944001'
    },
    {
        photo: 'path/to/photo6.jpg',
        name: 'Umesh Hazarika',
        contact: '8928001712'
    },




    {
        photo: 'path/to/photo6.jpg',
        name: 'Sarat Nath',
        contact: '9964006555'
    },
    {
        photo: 'path/to/photo6.jpg',
        name: 'Padma Phukan',
        contact: '8660965332'
    },
    {
        photo: 'path/to/photo6.jpg',
        name: 'Bhupen Barman',
        contact: '7760117846'
    },
    {
        photo: 'path/to/photo6.jpg',
        name: 'Palash',
        contact: '8197706510'
    },
    {
        photo: 'path/to/photo6.jpg',
        name: 'Jagat Sharma',
        contact: '9742178919'
    },
    {
        photo: 'path/to/photo6.jpg',
        name: 'Jiten Deka',
        contact: '9945081098'
    },
    {
        photo: 'path/to/photo6.jpg',
        name: 'Rahul',
        contact: '6363902346'
    },
    {
        photo: 'path/to/photo6.jpg',
        name: 'Litol',
        contact: '7975193112'
    },
    {
        photo: 'path/to/photo6.jpg',
        name: 'Biren Khatoniar',
        contact: '9134887182'
    },
    {
        photo: 'path/to/photo6.jpg',
        name: 'Gunadhar Nath',
        contact: '9880714223'
    },
    {
        photo: 'path/to/photo6.jpg',
        name: 'Jayanta Kalita',
        contact: '6001159859'
    },
    {
        photo: 'path/to/photo6.jpg',
        name: 'Susil Sarma',
        contact: '8861716387'
    },
    {
        photo: 'path/to/photo6.jpg',
        name: 'Biren',
        contact: '8135887181'
    },
    {
        photo: 'path/to/photo6.jpg',
        name: 'Lakhimi',
        contact: '9864033243'
    },
    {
        photo: 'path/to/photo6.jpg',
        name: 'Rajen Phukan',
        contact: '9740671808'
    },
    // {
    //     photo: 'path/to/photo6.jpg',
    //     name: 'Pranab J Bora(PJB)',
    //     contact: '7204468019'
    // },
    {
        photo: 'path/to/photo6.jpg',
        name: 'Munin Kumar Bhartee',
        contact: '9845048606'
    },
    {
        photo: 'path/to/photo6.jpg',
        name: 'Vilip Biswakarma',
        contact: '7896478756'
    },

    // Add more bhokot as needed
];

export default BhokotData;