import React, { useState } from 'react';
import Container from "../../../container";
import { Link } from 'react-router-dom';
import Event from '../component/event.js';
import { events } from '../component/eventData.js';

const Component = () => {

    const [currentMonth, setCurrentMonth] = useState(new Date());

    const getNextSixMonths = () => {
        const months = [];
        const start = new Date();
        for (let i = 0; i < 6; i++) {
            const month = new Date(start.getFullYear(), start.getMonth() + i, 1);
            months.push(month);
        }
        return months;
    };

    const getDaysInMonth = (date) => {
        const days = [];
        const year = date.getFullYear();
        const month = date.getMonth();
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);
        const firstDayIndex = firstDay.getDay(); // get the day of the week the first day falls on

        // add blank cells for days before the first day of the month
        for (let i = 0; i < firstDayIndex; i++) {
            days.push(null);
        }

        // add days of the month
        for (let i = 1; i <= lastDay.getDate(); i++) {
            days.push(new Date(year, month, i));
        }

        return days;
    };

    const daysInMonth = getDaysInMonth(currentMonth);

    const filteredEvents = events.filter(event =>
        event.date.getFullYear() === currentMonth.getFullYear() &&
        event.date.getMonth() === currentMonth.getMonth()
    );

    return (
        <>
            <Container headerType={'inner'}>
                <div id="container">
                    <div id="content_fullHeader">
                        <div className='inPgHeadBlueBg'>
                            <div className="widthfix">
                                <ul className='breadcrumbLink'>
                                    <li className="breadcrumbLink">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumbLink">
                                        Event Calendar
                                    </li>
                                </ul>
                                <div className='padBot60'></div>
                            </div>
                        </div>
                        <div className="widthfix">
                            <div className='events'>
                                <div className='eventIcol'>
                                    <div className="pgHeadText">Events for {currentMonth.toLocaleString('default', { month: 'long' })} {currentMonth.getFullYear()}</div>

                                    <ul className='eventIIcol'>
                                        <li className='eventIIcol'>
                                            <div className="eventCalendar">
                                                <div className="months">
                                                    {getNextSixMonths().map((month) => (
                                                        <button
                                                            key={month}
                                                            onClick={() => setCurrentMonth(month)}
                                                            className={month.getMonth() === currentMonth.getMonth() ? 'selected' : ''}
                                                        >
                                                            {month.toLocaleString('default', { month: 'long' })}
                                                        </button>
                                                    ))}
                                                </div>

                                                {/* Calendar */}
                                                <div className="days">
                                                    {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
                                                        <div key={day} className="day-name">{day}</div>
                                                    ))}
                                                    {daysInMonth.map((day, index) => (
                                                        <div key={index} className="day">
                                                            {day ? day.getDate() : ''}
                                                            {day && events.filter(event => event.date.toDateString() === day.toDateString()).map(event => (
                                                                <Event key={event.title} event={event} />
                                                            ))}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </li>
                                        <li className='eventIIcol'>
                                            <div className='calendar'>
                                                <div className="event-table">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: '130px' }}>Date</th>
                                                                <th>Title</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {filteredEvents.map((event) => (
                                                                <tr key={event.title}>
                                                                    <td>{event.date.toDateString()}</td>
                                                                    <td>{event.title}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;