const executiveMemberData = [
    { name: 'Chandan Kumar Sonowal', position: 'President', phone: '9886715560' },
    { name: 'Pranab Jyoti Borah', position: 'Vice President', phone: '7204468019' },
    { name: 'Simanta Mahanta', position: 'Vice President', phone: '9972598585' },
    { name: 'Santosh Kumar Nath', position: 'General Secretary', phone: '9900106821' },
    { name: 'Umesh Hazarika', position: 'Treasurer', phone: '7411012848' },
    { name: 'Pratyush Dutta', position: 'Asst. Treasurer', phone: '9845087152' },
    { name: 'Rajen Phukan', position: 'Secretary, Maintenance', phone: '9740671808' },
    { name: 'Ganesh Gogoi', position: 'Secretary, Naam Prasanga', phone: '9886341733' },
    { name: 'Bonita Hazarika', position: 'Secretary ,Cultural', phone: '9900172678' },
    { name: 'Pranjal Boruah', position: 'Secretary, PR & Website', phone: '9611123001' },
    { name: 'Kaushik Bhattacharya', position: 'Secretary, Magazine', phone: '9700044199' },
    { name: 'Binud Gogoi', position: 'Secretary, Library & Asset', phone: '9632137938' },
    { name: 'Aananda Bangthai', position: 'Executive Member', phone: '7619512575' },
    { name: 'Ashok Kumar Nath', position: 'Executive Member', phone: '8860294658' },
    { name: 'Bibhash Hazarika', position: 'Executive Member', phone: '9740175885' },
    { name: 'Honsha Narayan Kalita', position: 'Executive Member', phone: '9845720371' },
    { name: 'Lankeswar Nath', position: 'Executive Member', phone: '9035417693' },
    { name: 'Monangku Hazarika', position: 'Executive Member', phone: '8884442460' },
    { name: 'Paresh Borah', position: 'Executive Member', phone: '9880944001' },
];

export default executiveMemberData;