import React, { useState } from 'react';

const DonationForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        amount: '',
        phone: '',
        email: '',
        address1: '',
        address2: '',
        pin: '',
        type: '',
        kycdoc: '',
        otp: '',
        photo: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        let updatedFormData = {
            ...formData,
            [name]: value
        };

        if (name === 'type') {
            switch (value) {
                case 'donor':
                    updatedFormData.amount = '200000';
                    break;
                case 'life':
                    updatedFormData.amount = '15000';
                    break;
                case 'annual':
                    updatedFormData.amount = '1000';
                    break;
                case 'associate':
                    updatedFormData.amount = '250';
                    break;
                default:
                    updatedFormData.amount = '';
            }
        }

        setFormData(updatedFormData);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form Data Submitted: ', formData);
    };

    const formatAmount = (amount) => {
        return new Intl.NumberFormat('en-IN').format(amount);
    };

    const getSubmitButtonText = () => {
        if (formData.amount) {
            return `Continue to Pay ₹${formatAmount(formData.amount)}`;
        }
        return 'Continue to Pay';
    };

    return (
        <div className="form-container">
            <form onSubmit={handleSubmit}>
                <ul className='formIIIcol'>
                    <li>
                        <div className='labelHead'>Membership Type:</div>
                        <select name="type" value={formData.type} onChange={handleChange} required>
                            <option value="">Select Membership Type</option>
                            <option value="associate">Associate Member</option>
                            <option value="annual">Regular Member</option>
                            <option value="life">Life Member</option>
                            <option value="donor">Donor Member</option>
                        </select>
                    </li>
                    <li>
                        <div className='labelHead'>Amount:</div>
                        <input type="text" name="amount" value={formatAmount(formData.amount)} onChange={handleChange} readOnly required />
                    </li>
                    <li>
                        <div className='labelHead'>Name:</div>
                        <input type="text" name="name" value={formData.name} onChange={handleChange} required />
                    </li>
                    <li>
                        <div className='labelHead'>Email ID:</div>
                        <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                    </li>
                    <li>
                        <div className='labelHead'>Phone:</div>
                        <div className="getOtpBtnBlk">
                            <input type="button" className="getOtpBtn" value="Get OTP" />
                        </div>
                        <input type="number" name="phone" className='no-spinner' value={formData.phone} onChange={handleChange} required />
                    </li>
                    <li>
                        <div className='labelHead'>OTP:</div>
                        <input type="otp" name="otp" value={formData.otp} onChange={handleChange} required />
                    </li>
                    <li>
                        <div className='labelHead'>Address Line 1:</div>
                        <input type="text" name="address1" value={formData.address1} onChange={handleChange} required />
                    </li>
                    <li>
                        <div className='labelHead'>Address Line 2:</div>
                        <input type="text" name="address2" value={formData.address2} onChange={handleChange} />
                    </li>
                    <li>
                        <div className='labelHead'>PIN:</div>
                        <input type="text" name="pin" value={formData.pin} onChange={handleChange} required />
                    </li>
                    <li>
                        <div className='labelHead'>Photo Upload:</div>
                        <input type="text" name="photo" value={formData.photo} onChange={handleChange} required />
                    </li>
                    <li>
                        <div className='labelHead'>KYC Document:</div>
                        <select name="kycdoc" value={formData.kycdoc} onChange={handleChange} required>
                            <option value="">Select Document Type</option>
                            <option value="aadhar">Aadhar</option>
                            <option value="passport">Passport</option>
                            <option value="pan">PAN</option>
                            <option value="voterid">Voter ID</option>
                            <option value="lisence">Driving License</option>
                        </select>
                    </li>
                    <li>
                        <div className='labelHead'>KYC Document Number:</div>
                        <input type="text" name="kycdoc" value={formData.kycdoc} onChange={handleChange} required />
                    </li>
                </ul>
                <div className='buttonLabel'><button type="submit">{getSubmitButtonText()}</button></div>
            </form>
        </div>
    );
};

export default DonationForm;
