import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Home from "../screens/home";
import Xorai from "../screens/headertabs/xorai";
import Privacy from "../screens/privacy";
import Terms from "../screens/terms";
import Refund from "../screens/refund";
import Membership from "../screens/headertabs/member/membership";
import Memberlist from "../screens/headertabs/member/memberlist";
import MemberForm from "../screens/headertabs/member/memberForm";
import EventPhotoList from "../screens/headertabs/gallery/photo/EventPhotoList";
import EventPhotos from "../screens/headertabs/gallery/photo/EventPhotos";
import EventVideoList from "../screens/headertabs/gallery/video/EventVideoList";
import EventVideos from "../screens/headertabs/gallery/video/EventVideos";
import Contact from "../screens/headertabs/contact";
import About from "../screens/headertabs/about";
import Library from "../screens/headertabs/library";
import Publication from "../screens/headertabs/publication";
import Bhokot from "../screens/headertabs/bhokot";
import EventCalendar from "../screens/headertabs/events/calendar";
import EventUpcoming from "../screens/headertabs/events/upcoming";
import AdminLogin from "../screens/admin/login";
import AdminMemberList from "../screens/admin/member";

const RouteLinks = [
  { id: 1, path: "", Component: Home },
  { id: 2, path: "xorai", Component: Xorai },
  { id: 3, path: "membership", Component: Membership },
  { id: 4, path: "memberlist", Component: Memberlist },
  { id: 5, path: "memberform", Component: MemberForm },
  { id: 6, path: "privacy", Component: Privacy },
  { id: 7, path: "terms", Component: Terms },
  { id: 8, path: "galleryphotoevents", Component: EventPhotoList },
  { id: 9, path: "photo-event/:photoEventKey", Component: EventPhotos },
  { id: 10, path: "galleryvideoevents", Component: EventVideoList },
  { id: 11, path: "video-event/:videoEventKey", Component: EventVideos },
  { id: 12, path: "contact", Component: Contact },
  { id: 13, path: "about", Component: About },
  { id: 14, path: "library", Component: Library },
  { id: 15, path: "publication", Component: Publication },
  { id: 16, path: "bhokot", Component: Bhokot },
  { id: 17, path: "eventcalendar", Component: EventCalendar },
  { id: 18, path: "eventupcoming", Component: EventUpcoming },
  { id: 19, path: "refund", Component: Refund },
  { id: 20, path: "admin", Component: AdminLogin },
  { id: 21, path: "adminmemberlist", Component: AdminMemberList },
];

function PrivateRoute({ children }) {
  let loggedin = sessionStorage.getItem("user");
  return loggedin ? children : <Navigate to="/" />;
}

// const RendorRoutes = () => {
//   return (
//     <Routes>
//       {RouteLinks.map((x) => {
//         if (x.Session) {
//           return <Route key={x.id} path={"/" + x.path} exact
//             element={
//               <PrivateRoute>
//                 <x.Component />
//               </PrivateRoute>
//             }
//           />
//         } else {
//           return <Route key={x.id} path={"/" + x.path} exact
//             element={<x.Component />} />
//         }
//       })}
//     </Routes>
//   );
// };

// export default RendorRoutes;

const RendorRoutes = () => {
  return (
    <Routes>
      {RouteLinks.map((x) => {
        if (x.Session) {
          return (
            <Route
              key={x.id}
              path={"/" + x.path}
              element={
                <PrivateRoute>
                  <x.Component />
                </PrivateRoute>
              }
            />
          );
        } else {
          return <Route key={x.id} path={"/" + x.path} element={<x.Component />} />;
        }
      })}
    </Routes>
  );
};

export default RendorRoutes;

// const RendorRoutes = () => {
//   return (
//     <Routes>
//       {RouteLinks.map((x) => {
//         const Component = x.Component;
//         if (x.Session) {
//           return (
//             <Route
//               key={x.id}
//               path={"/" + x.path}
//               element={
//                 <PrivateRoute>
//                   <Component />
//                 </PrivateRoute>
//               }
//             />
//           );
//         } else {
//           return <Route key={x.id} path={"/" + x.path} element={<Component />} />;
//         }
//       })}
//     </Routes>
//   );
// };

// export default RendorRoutes;