import React from 'react';

const AboutSankardev = () => {
    return (
        <div className='padBot20'>
            <div className='aboutUs'>
                <div className='pgTxtBold'>About Sreemanta Sankardeva</div>
                <div className='pgTxtNormal'>Sreemanta Sankardeva, also known as Srimanta Sankardev, was a 15th-16th century Assamese polymath, saint-scholar, poet, playwright, and social-religious reformer. He was a pivotal figure in the cultural and religious history of Assam, India.</div>
                <div className='pgTxtBold'>Early Life and Education</div>
                <div className='pgTxtNormal'>Born in 1449 in Alipukhuri near Bordowa in Nagaon, Assam, Sankardeva exhibited extraordinary talent and intellect from a young age. He received his education under the guidance of Mahendra Kandali, a renowned scholar of the time.</div>
                <div className='pgTxtBold'>Contributions to Religion and Society</div>
                <div className='pgTxtNormal'>Sankardeva is best known for founding the Neo-Vaishnavism movement in Assam. This movement emphasized devotion (bhakti) to a single God, Vishnu (often in the form of Krishna), rejecting caste distinctions and idol worship. His teachings promoted social harmony and religious inclusivity.</div>
                <div className='pgTxtBold'>Literary Works</div>
                <div className='pgTxtNormal'>A prolific writer, Sankardeva composed numerous literary works, including:</div>
                <ul className="bulletPoints_un">
                    <li className="bp_circleOutline"><b>Kirtan Ghoxa:</b> A collection of devotional songs praising Lord Krishna.</li>
                    <li className="bp_circleOutline"><b>Borgeets:</b> These are a series of devotional songs that are sung in various religious ceremonies.</li>
                    <li className="bp_circleOutline"><b>Ankia Naat:</b> One-act plays written in Assamese, which depicted various religious stories and moral lessons.</li>
                </ul>
                <div className='pgTxtBold'>Art and Culture</div>
                <div className='pgTxtNormal'>Sankardeva also contributed significantly to the arts. He established the Satra and Namghar institutions, which became centers of religious and cultural activities. These institutions play a crucial role in preserving Assamese culture and traditions to this day.</div>
                <div className='pgTxtBold'>Legacy</div>
                <div className='pgTxtNormal'>Sreemanta Sankardeva's impact on Assamese culture, religion, and society is immense. His teachings and literary contributions continue to influence the social and cultural fabric of Assam. Annual celebrations and festivals commemorate his life and works, highlighting his enduring legacy.</div>
            </div>
        </div>
    );
};

export default AboutSankardev;
