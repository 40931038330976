import React from 'react';
import MembershipTable from './membershipTable';

const TabContent = ({ activeTab, inPageTabs, tableData }) => {
    let filteredRows;

    if (activeTab === 'All Member') {
        filteredRows = tableData.rows;
    } else {
        const activeTabContent = inPageTabs.find(tab => tab.name === activeTab).content;
        filteredRows = tableData.rows.filter(row => row[tableData.membershipTypeIndex] === activeTabContent);
    }

    return (
        <div className="memberlistTabContent">
            <MembershipTable headers={tableData.headers} rows={filteredRows} />
        </div>
    );
};

export default TabContent;
