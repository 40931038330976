import { React, Header, Footer } from "../../common";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactModal from 'react-modal';
import { events } from '../headertabs/events/component/eventData.js';
import {
  HomeVideo, HomeViewBottom, Membership
} from "./childs";
import CustomButton from "../../component/CustomButton.js";

ReactModal.setAppElement('#root');

const Component = () => {
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setModalIsOpen(true);
  }, []);

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set today's time to midnight

  const sixtyDaysFromToday = new Date(today);
  sixtyDaysFromToday.setDate(sixtyDaysFromToday.getDate() + 60); // Calculate the date 60 days from today

  const filteredEvents = events.filter(event => {
    const eventDate = new Date(event.date); // Ensure event.date is a Date object
    eventDate.setHours(0, 0, 0, 0); // Set event date time to midnight
    return (
      eventDate >= today && eventDate <= sixtyDaysFromToday
    );
  });

  return (
    <>

      <div>
        <ReactModal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          contentLabel="Event Table"
          className="modalEventsHome"
          overlayClassName="overlay"
        >
          <div className="popEventTable">
            <div className="headLabel">Upcoming Events</div>

            <table>
              <thead>
                <tr>
                  <th style={{ width: '130px' }}>Date</th>
                  <th>Event Name</th>
                </tr>
              </thead>
              <tbody>
                {filteredEvents.map((event) => (
                  <tr key={event.title}>
                    <td>{new Date(event.date).toDateString()}</td>
                    <td>{event.title}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="buttonLabel">
              <button onClick={() => setModalIsOpen(false)} className="btnRed_M_Bold">Close</button>
              <CustomButton
                type="button"
                value="View More"
                css="btnRed_M_Bold marLeft5"
                onClick={() => navigate('/eventcalendar')}
              />
            </div>
          </div>
        </ReactModal>
      </div>
      <Header type={"PreLogin"} />

      {/*Home Video*/}
      <HomeVideo />

      {/*Homepage Bottom*/}
      <HomeViewBottom />

      {/*Membership*/}
      <Membership />

      {/*Footer*/}
      <Footer type={"Full"} />
    </>
  );
};

export default Component;
