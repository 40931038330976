import React from 'react';
import Container from "../../container";
import { Link } from 'react-router-dom';
import BhokotData from '../../components/bhokotData';


const Component = () => {



    return (
        <>
            <Container headerType={'inner'}>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div className='inPgHeadBlueBg'>
                            <div className="widthfix">
                                <ul className='breadcrumbLink'>
                                    <li className="breadcrumbLink">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumbLink">
                                        Bhokot List
                                    </li>
                                </ul>
                                <div className='padBot60'></div>
                            </div>
                        </div>
                        <div className="widthfix">
                            <div className='events'>
                                <div className='eventIcol'>
                                    <div className="pgHeadText">Bhokot List</div>
                                    <ul className="bhokotDisplay">
                                        {BhokotData.map((member, index) => (
                                            <li key={index} className="bhokotBlk">
                                                {/* <img src={member.photo} alt={member.name} className="bhokotPhotoBlk" /> */}
                                                <div className="member-info">
                                                    <h3 className="member-name">{member.name}</h3>
                                                    <p className="member-contact">{member.contact}</p>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;