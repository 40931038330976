import React from 'react';
import Container from "../container";
import { Link } from 'react-router-dom';

const Component = () => {



    return (
        <>
            <Container headerType={'inner'}>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div className='inPgHeadBlueBg'>
                            <div className="widthfix">
                                <ul className='breadcrumbLink'>
                                    <li className="breadcrumbLink">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumbLink">
                                        Privacy Policy
                                    </li>
                                </ul>
                                <div className='padBot60'></div>
                            </div>
                        </div>
                        <div className="widthfix">
                            <div className='events'>
                                <div className='eventIcol'>
                                    <div className="pgHeadText">Privacy Policy</div>
                                    <div className='contentPad20'>
                                        <div className='pgTxtBold'>Our Commitment To Privacy</div>
                                        <div className='pgTxtNormal'>Your privacy is important to us. To better protect your privacy we have provided this policy explaining our online information practices and the choices you can make about the way your information is collected and used. To make this policy easy to find, we have made it available on the homepage(s) of our Website and at every point where Personally Identifiable Information (hereinafter defined) may be requested.</div>
                                        <div className='pgTxtNormal'>Capitalized terms used but not defined herein shall have the meanings ascribed to them in the <Link to="/terms">Terms of Use</Link>.</div>
                                        <div className='pgTxtNormal'>Date:  July 20, 2024.</div>
                                        <div className='pgTxtBold'>Preliminary</div>
                                        <div className='pgTxtNormal'>Sreemanta Sankardeva Cultural Society,  including its subsidiaries and affiliates (“SSCSB”, the “Website Owner” or “we” or “us” or “our” as the context may require) respects your privacy. This privacy policy has been compiled to better serve those who are concerned with how their Personally Identifiable Information (hereinafter defined) is being used online (the “Policy”). For purposes of the Policy, “Personally Identifiable Information” is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read the Policy carefully before using the Website to understand how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our Web Application. If you do not agree with our policies and practices, you have a choice not to use or discontinue using the Web Application. By accessing or using the Web Application, you agree to the Policy. The Policy describes:</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">the types of information that we may collect from you when you access or use the Web Application; and</li>
                                            <li className="bp_circleOutline">our practices for collecting, using, maintaining, protecting and disclosing that information.</li>
                                        </ul>
                                        <div className='pgTxtBold'>Website of the Policy </div>
                                        <div className='pgTxtNormal'>The Policy applies only to information we collect through the Web Application, in email, voice, call, text and other electronic communications sent through or in connection with the Web Application.</div>
                                        <div className='pgTxtNormal'>The Policy does not apply to information that you provide to, or that is collected by, any third-party, such as experiences, data providers and social networks that you use in connection with the Web Application. We encourage you to consult directly with such third-parties about their privacy practices.</div>
                                        <div className='pgTxtNormal'>Modification of the Policy</div>
                                        <div className='pgTxtNormal'>We reserve the right, at our sole discretion, to change, modify or replace the Policy at any time by posting a notice on the Website or by sending you notice through the Website or via Email. It is your responsibility to check the Policy periodically for changes. Your continued use of the Website following the posting of any changes to the Policy constitutes acceptance of those changes.</div>
                                        <div className='pgTxtNormal'>How we collect information, use and share it</div>
                                        <div className='pgTxtNormal'>When registering with our Web Application, as appropriate, you may be asked to enter your name, Email address, phone number or other details to help you with your experience. </div>
                                        <div className='pgTxtNormal'>If you connect to the Website using credentials from Third Party Web Applications, you authorise us to collect your authentication information, such as your username and encrypted access credentials. We may also collect other information available on or through your third party Website account, including your name, profile picture, country, hometown, Email address, date of birth, gender, family's names, profile pictures, and networks.</div>
                                        <div className='pgTxtNormal'>We will never scan or import your contacts unless you explicitly allow us to. If you choose to do so, we will only use your contact information to help you find contacts who also use the Web Application.</div>
                                        <div className='pgTxtNormal'>In addition to the personal information you share with us, we receive and store certain types of information when you use the Web Application. This information often includes, device-specific information such as your hardware model, operating system version, unique device identifiers, and mobile network information including phone number and historical details of how you used our Web Application, such as your search queries. This enable us to make suggestions based on your general location, such as recommendations and relevant places/experiences/businesses/outlets around you or relevant promotions/deals/offers.</div>
                                        <div className='pgTxtNormal'>We may also collect and store broad, non-specific location details, which we derive from your IP address. Device event information includes information pertaining to crashes, system activity, hardware settings, browser type, browser language, the date and time of your request and referral/sharing URL.</div>
                                        <div className='pgTxtNormal'>The Website includes various location-based features, such as displaying nearby posts or to provide better suggestions. To provide these location-based features, we may collect and use precise location data, including the real-time geographic location of your mobile device. This information will not be shared without the explicit permission of the user.</div>
                                        <div className='pgTxtNormal'>To serve you relevant content when you use the Web Application, we may collect and store information (including personal information) locally on your device using mechanisms such as browser web storage (including HTML 5) and Website data caches. </div>
                                        <div className='pgTxtNormal'>The Website includes a unique Website number. This number and information about your installation (for example, the operating system type and Website version number) may be sent to our servers when you install or uninstall the Website or when it periodically contacts our servers for automatic updates.</div>
                                        <div className='pgTxtNormal'>We may reach out to our users through Email, short messaging service (“SMS”) and In-App communications to inform you about service updates, recommendations and relevant places/experiences/businesses/outlets and other such relevant information. To help us make these Emails/SMS/In-App communications (push notifications or interstitials) more useful and interesting, we often receive and analyse information on how you interact with these communications. We may receive information about you from other sources and add it to our account information.</div>
                                        <div className='pgTxtNormal'>We use the information you provide us to enhance the functionality and improve the quality of our Web Application, and to personalize your experience while using the Web Application. We also use this information to display relevant advertising, provide support to you, communicate with you, and comply with our legal obligations. We further use the information we collect from and about you for a variety of purposes, including to:</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">process and respond to your queries;</li>
                                            <li className="bp_circleOutline">understand our users (what they do on our Web Application, what features they like, how they use them, etc.), improve the content and features of our Website (such as by personalizing content to your interests), process and complete your actions, and make special offerings;</li>
                                            <li className="bp_circleOutline">administer the Website and diagnose technical problems;</li>
                                            <li className="bp_circleOutline">send you communications that you have requested or that may be of interest to you by way of Emails, or courier, or registered post, or telephone calls, or any other mode of communication; </li>
                                            <li className="bp_circleOutline">generate and review reports and data about, and to conduct research on, our user base and Website usage patterns; and</li>
                                            <li className="bp_circleOutline">in any other way we may describe when you provide the information.</li>
                                        </ul>
                                        <div className='pgTxtNormal'>We may also share your preferences or the services availed by you with your network on the Website for marketing and other promotional activities of the Web Application.</div>
                                        <div className='pgTxtNormal'>We may also use your information to contact you about our own and third-party goods and services that may be of interest to you. If you do not want us to use your information in this way, please check the relevant box located on the form on which we collect your data and/or adjust your user preferences in your account profile.</div>
                                        <div className='pgTxtNormal'>We may use the information we have collected from you to enable us to display advertisements to our advertisers’ target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria.</div>
                                        <div className='pgTxtNormal'>You have the option to share your personalised codes and/or links in relation to certain experiences in the Website with your friends. If you share your personalised codes and/or links in relation to certain experiences in the Website with your friends and your friends share those personalised codes and/or links onwards, we may:</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">use your friends’ and their friends’ (collectively, the “User Forwards”) contact information to process your request; and</li>
                                            <li className="bp_circleOutline">create a social circle within the Website linking you with the User Forwards. </li>
                                        </ul>
                                        <div className='pgTxtNormal'>Please ensure that you have all the requisite permissions and authorizations to share any information with us. It is your sole responsibility to ensure that you have obtained the necessary consents in relation to any information which may relate to third parties apart from yourself.</div>
                                        <div className='pgTxtNormal'>If you exchange messages with others through the Web Application, we may store them in order to process and deliver them, allow you to manage them and investigate possible violations of our  Terms of Use.</div>
                                        <div className='pgTxtNormal'>We may automatically collect certain information about the computer or devices (including mobile devices) you use to access the Website and about your use of the Web Application, even if you use the Website without registering or logging in such as:</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">details of your use of the Web Application, including traffic data, location data, logs and other communication data and the resources that you access and use on or through the Web Application;</li>
                                            <li className="bp_circleOutline">information about your computer, Internet connection and mobile device, including your IP address, operating systems, platforms, browser type, other browsing information (connection, speed, connection type etc.), device type, device’s unique device identifier, mobile network information and the device’s telephone number;</li>
                                            <li className="bp_circleOutline">metadata and other information associated with other files stored on your mobile device, such as, photographs, audio and video clips, personal contacts and address book information;</li>
                                            <li className="bp_circleOutline">real-time information about the location of your device, as permitted by you;</li>
                                            <li className="bp_circleOutline">the URL of the last web page you visited before visiting the Web Application;</li>
                                            <li className="bp_circleOutline">unique mobile device identifier (e.g. IDFA or other device IDs on Apple&trade; devices like the iPhone® and iPad®) and mobile device IDs (the unique identifier assigned to a device by the manufacturer), instead of cookies, to recognize you (we may do this to store your preferences and track your use of the Web Applications. Unlike cookies, mobile device IDs cannot be deleted. Advertising companies may use device IDs to track your use of our Web Applications, track the number of advertisements displayed, measure advertising performance and display advertisements that are more relevant to you. Analytics companies may use mobile device IDs to track your usage of the Web Application);</li>
                                            <li className="bp_circleOutline">your preferences and settings such as time zone and language;</li>
                                            <li className="bp_circleOutline">information about your activity on the Web Application, such as your search queries, comments, domain names, search results selected, number of clicks, pages viewed and the order of those pages, how long you visited the Web Application, the date and time you used the Web Application, error logs, and other similar information; and</li>
                                            <li className="bp_circleOutline">the online or offline status of the Website on your device.</li>
                                        </ul>
                                        <div className='pgTxtBold'>Legal Basis for Processing Your Information</div>
                                        <div className='pgTxtNormal'>We rely on the following legal grounds to process your personal information:</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline"><b>Consent-</b><br />We may use your personal information as described in the Policy subject to your consent. To withdraw your consent, please contact us at <Link to="mailto:sscsb.support@gmail.com">sscsb.support@gmail.com.</Link>. You may also refrain from providing, or withdraw, your consent for cookies. Please see the provision in the Policy providing more information on opt-outs.</li>
                                            <li className="bp_circleOutline"><b>Performance of a contract-</b><br />We may need to collect and use personal information of the Website users, as applicable, to perform our contractual obligations.</li>
                                            <li className="bp_circleOutline"><b>Legitimate Interests-</b><br />We may use your personal information for our legitimate interests to provide our Website and to improve our services and the content on the Web Application. We process information on behalf of our customers who have legitimate interests in operating their businesses. We may use technical information as described in the Policy and use personal information for our marketing purposes consistent with our legitimate interests and any choices that we offer or consents that may be required under applicable law.</li>
                                        </ul>
                                        <div className='pgTxtBold'>General Information Disclosures </div>
                                        <div className='pgTxtNormal'>We may disclose personal information that we collect or you provide, as described in the Policy:</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">to our subsidiaries and affiliates, which are entities under common ownership or control of SSCSB;</li>
                                            <li className="bp_circleOutline">to contractors, service providers and other third-parties whom we use to support our business (e.g. Exotel, Google, Cashfree, RazorPay) and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them;</li>
                                            <li className="bp_circleOutline">to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation or similar proceeding, in which personal information held by us about the users of our Website are among the assets transferred;</li>
                                            <li className="bp_circleOutline">to third-parties to market their products or services to you if you have consented to receive promotional updates. We contractually require these third-parties to keep personal information confidential and use it only for the purposes for which we disclose it to them. You have the right to contact us to discontinue these messages;</li>
                                            <li className="bp_circleOutline">to fulfil the purpose for which you provide it; and </li>
                                            <li className="bp_circleOutline">to outside vendors that we use for a variety of purposes, such as to send you Emails and messages on behalf of our other users, push notifications to your mobile device on our behalf, provide voice recognition services to process your spoken queries and questions, help us analyse use of the Web Application, and process and collect payments. Some of our products, services and databases are hosted by third party hosting services providers. We may share information about you with these vendors only to enable them to perform their services.</li>
                                        </ul>
                                        <div className='pgTxtNormal'>We may share your information when we believe in good faith that such sharing is reasonably necessary in order to investigate, prevent, or take action regarding possible illegal activities or to comply with legal process. We may also share your information to investigate and address threats or potential threats to the physical safety of any person, to investigate and address violations of the Policy or the Terms of Use, or to investigate and address violations of the rights of third parties and/or to protect the rights, property and safety of SSCSB, our employees, users, or the public. This may involve the sharing of your information with law enforcement, government agencies, courts, and/or other organizations on account of legal request such as subpoena, court order or government demand to comply with the law. You understand that we may not be able to notify you of such disclosure if we are not permitted to do so under applicable law.</div>
                                        <div className='pgTxtBold'>Precise Location Information and How to Opt Out</div>
                                        <div className='pgTxtNormal'>When you use one of our location-enabled services in the Website (for example, when you access the Website from a mobile device), we may collect and process information about your mobile device’s GPS location (including the latitude, longitude or altitude of your mobile device) and the time the location information is recorded to customize the services offered in the Website with location-based information and features (for example, to inform you about experiences in your area). Some of these services require your personal data for the feature to work and we may associate location data with your device ID and other information we hold about you.</div>
                                        <div className='pgTxtNormal'>We keep this data for no longer than is reasonably necessary for providing services to you. If you wish to use the particular feature, you will be asked to consent to your data being used for this purpose. You can withdraw your consent at any time by disabling the GPS or other location-tracking functions on your device, provided your device allows you to do this.</div>
                                        <div className='pgTxtNormal'>In an effort to improve the Web Application, we occasionally request that some users and recipients voluntarily complete surveys, which may be collected by an independent third party. Individual survey responses are confidential, although non-personal information may be collected and disclosed to third parties. If you do not wish to participate in a survey, simply decline to participate when asked.</div>
                                        <div className='pgTxtBold'>Cookies, Clear Graphics Interchange Formats (“GIFs”) and Other Electronic Tools</div>
                                        <div className='pgTxtNormal'>We, and third parties with whom we partner, may use standard technologies called “cookies” and clear GIFs to collect information about how our website is used. A cookie is a small data text file, that is stored on your computer (if your web browser permits) that enables us to recognize you (for example, as a registered user) when you visit our Web Application, store your preferences and settings, enhance your experience by delivering content and advertising specific to your interests, perform research and analytics, track your use of our services, and assist with security and administrative functions. Cookies may be persistent or stored only during an individual session. Clear GIFs: Some of our service providers may employ clear GIFs (also known as pixel tags, single pixel GIFs, web beacons, mobile device IDs, “flash cookies” or action tags) for our benefit to help us measure advertising effectiveness. Clear GIFs help us better manage content on the Website by informing us of what content is effective. Clear GIFs are tiny graphics with a unique identifier, embedded invisibly on a webpage (or an online ad or Email), and is used to count or track things like activity on a webpage or ad impressions or clicks, as well as to access cookies stored on users' computers, similar in function to cookies, and are used to track the online movements of our users. The main difference between the two is that clear GIFs are invisible on the page and are much smaller, about the size of the period at the end of this sentence. </div>
                                        <div className='pgTxtNormal'>Most browsers are set to automatically allow cookies. Please note it may be possible to disable some (but not all) cookies through your device or browser settings, but doing so may interfere with certain functionality on the services. Major browsers provide users with various options when it comes to cookies. Users can usually set their browsers to block all third-party cookies (which are those set by third-party companies collecting information on websites operated by other companies), block all cookies (including first-party cookies such as the ones we use to collect search activity information about our users), or block specific cookies. To modify your cookie settings, please visit your browser’s help settings. You will need to opt out on each browser and each device you use to access the services. Flash cookies operate differently than browser cookies and cannot be removed or blocked via web browser settings. By using our services with your browser set to accept cookies you are consenting to our use of cookies.</div>
                                        <div className='pgTxtNormal'>Third parties whose products or services are accessible or advertised through the services, including social media services, may also use cookies or similar tools, and we advise you to check their privacy policies for information about their cookies and other practices. We do not control the practices of such partners and their privacy policies govern their interactions with you.</div>
                                        <div className='pgTxtBold'>Information from Third Parties</div>
                                        <div className='pgTxtNormal'>We may collect, process and store your user ID associated with any social media account (such as your Facebook&trade;, LinkedIn&trade; and Google&trade; account) that you use to sign into the Website or connect with or use with the Web Application.</div>
                                        <div className='pgTxtNormal'>When you sign in to your account with your social media account information, or otherwise connect to your social media account with the Web Application, you consent to our collection, storage, and use, in accordance with the Policy, of the information that you make available to us through the social media interface. This could include, without limitation, any information that you have made public through your social media account, information that the social media service shares with us, or information that is disclosed during the sign-in process. Please see your social media provider's privacy policy and help center for more information about how they share information when you choose to connect your account.</div>
                                        <div className='pgTxtNormal'>We may also obtain information about you from third parties such as partners, marketers, third-party websites, and researchers, and combine that information with information which we collect from or about you.</div>
                                        <div className='pgTxtBold'>Anonymous or De-Identified Data</div>
                                        <div className='pgTxtNormal'>We may anonymize and/or de-identify information collected from you through the Website or via other means, including via the use of third-party web analytic tools as described below. As a result, our use and disclosure of aggregated and/or de-identified information is not restricted by the Policy, and it may be used and disclosed to others without limitation.</div>
                                        <div className='pgTxtBold'>Accessing and correcting your personal information</div>
                                        <div className='pgTxtNormal'>We will take reasonable steps to accurately record the personal information that you provide to us and any subsequent updates.</div>
                                        <div className='pgTxtNormal'>We encourage you to review, update, and correct the personal information that we maintain about you, and you may request that we delete personal information about you that is inaccurate, incomplete, or irrelevant for legitimate purposes, or are being processed in a way which infringes any applicable legal requirement.</div>
                                        <div className='pgTxtNormal'>Your right to review, update, correct, and delete your personal information may be limited, subject to the applicable law of your jurisdiction:</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">if your requests are abusive or unreasonably excessive,</li>
                                            <li className="bp_circleOutline">where the rights or safety of another person or persons would be encroached upon; and/or</li>
                                            <li className="bp_circleOutline">if the information or material you request relates to existing or anticipated legal proceedings between you and us, or providing access to you would prejudice negotiations between us or an investigation of possible unlawful activity. Your right to review, update, correct, and delete your information is subject to our records retention policies and applicable law for the time being in force, including any statutory retention requirements.</li>
                                        </ul>
                                        <div className='pgTxtBold'>Information Security</div>
                                        <div className='pgTxtNormal'>We have implemented industry-standard appropriate physical, electronic, and managerial procedures to safeguard and help prevent unauthorised access to your information and to maintain data security.</div>
                                        <div className='pgTxtNormal'>These safeguards take into account the sensitivity of the information that we collect, process and store and the current state of technology. We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it.</div>
                                        <div className='pgTxtNormal'>We use the Secure Sockets Layer (“SSL”) technology. SSL encrypts your order information as additional protection against the decoding of that information by anyone other than us. However, despite all reasonable security precautions and processes, such as password protection, encryption technology, and use of firewall technology, no data transmission over the Internet can be guaranteed to be 100% secure. AS A RESULT, WHILE WE STRIVE TO PROTECT YOUR PERSONALLY IDENTIFIABLE INFORMATION, WE CANNOT GUARANTEE OR WARRANT THE SECURITY OF ANY INFORMATION YOU TRANSMIT TO OR FROM THE WEBSITE AND YOU DO SO AT YOUR OWN RISK. Once we receive your transmission, we will take commercially reasonable precautions to protect its security on our systems. Your password(s) will be your personal, secret entry code(s) to access your personal account(s) on the Web Application. When you access your account information, it is kept on a secure server and a password is needed to access any account areas. You can change your password as many times as you like. We recommend that you keep your password in a safe place and that you always sign off your account and close your browser window once you have completed your visit. These precautions will help prevent someone else from accessing your account, especially if you share a computer with other people or use a computer in a public place such as an Internet café or library.</div>
                                        <div className='pgTxtNormal'>We assume no liability or responsibility for disclosure of your information due to errors in transmission, unauthorised third-party access, or other causes beyond our control. You play an important role in keeping your personal information secure. You should not share your user name, password, or other security information for your SSCSB account with anyone. If we receive instructions using your user name and password, we will consider that you have authorised the instructions.</div>
                                        <div className='pgTxtNormal'>In case you would like to know more about how we keep your information secure, please reach out to us at <Link to="mailto:sscsb.support@gmail.com">sscsb.support@gmail.com.</Link> with your specific queries.</div>
                                        <div className='pgTxtBold'>Privacy of Children </div>
                                        <div className='pgTxtNormal'>We strongly believe in protecting the privacy of children. To achieve this objective we:</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">do not knowingly permit persons below 18 (eighteen) years of age to register as a user of the Web Application;</li>
                                            <li className="bp_circleOutline">do not knowingly collect or maintain Personally Identifiable Information on our Website from persons below 18 (eighteen) years of age; and</li>
                                            <li className="bp_circleOutline">no part of our Website is directed to persons below 18 (eighteen) years of age, or a minimum age prescribed for a particular purpose by applicable law for the time being in force, whichever is lesser. If you are below 18 (eighteen) years of age or below a minimum age prescribed for a particular purpose by applicable law for the time being in force, whichever is lesser, then please do not use or access our services at any time or in any manner. We will take appropriate steps to delete any Personally Identifiable Information of persons below 18 (eighteen) years of age or a minimum age prescribed for a particular purpose by applicable law for the time being in force, whichever is lesser, that has been collected on our Website upon learning of the existence of such Personally Identifiable Information.</li>
                                        </ul>
                                        <div className='pgTxtNormal'>If we become aware that a person submitting personal information is below 18 (eighteen) years of age or a minimum age prescribed for a particular purpose by applicable law for the time being in force, whichever is lesser, we will delete the account and all related information as soon as possible. If you believe we might have any information from or about a person below 18 (eighteen) years of age or a minimum age prescribed for a particular purpose by applicable law for the time being in force, whichever is lesser, please contact us at <Link to="mailto:sscsb.support@gmail.com">sscsb.support@gmail.com.</Link></div>
                                        <div className='pgTxtBold'>Compliance with data protection regulations </div>
                                        <div className='pgTxtNormal'>The information we obtain from or about you may be processed and stored in India and/or our other various servers located across the globe, which may provide for different data protection rules than the country in which you reside.</div>
                                        <div className='pgTxtNormal'>Personally Identifiable Information collected by us may be transferred to, and stored at, any of our affiliates, partners or service providers which may be inside or outside the country you reside in / economic area your country is part of. By submitting your personal data, you agree to such transfers. Your Personally Identifiable Information may be transferred to countries that do not have the same data protection laws as the country in which you initially provided the information. When we transfer or disclose your Personally Identifiable Information to other countries, we will protect that information as described in the Policy to the extent possible or permissible in accordance with applicable law for the time being in force. Where relevant, we will ensure appropriate contractual safeguards to ensure that your information is processed with the highest standards of transparency and fairness.</div>
                                        <div className='pgTxtNormal'>If you have any questions relating to your personal data, please write to us at <Link to="mailto:sscsb.support@gmail.com">sscsb.support@gmail.com.</Link>.</div>
                                        <div className='pgTxtBold'>Data retention and account termination</div>
                                        <div className='pgTxtNormal'>Ordinarily, we will retain your information for as long as necessary to provide you with our services. The length of time we retain Personally Identifiable Information for depends on the purposes for which we collect and use it and/or as required to comply with applicable laws for the time being in force and to establish, exercise or defend our legal rights.</div>
                                        <div className='pgTxtNormal'>You can close your account by visiting your profile settings page on our Website or by writing to us at <Link to="mailto:sscsb.support@gmail.com">sscsb.support@gmail.com.</Link>. We will remove your public posts from view and/or dissociate them from your account profile, but we may retain information about you for the purposes authorised under the Policy unless prohibited by applicable law for the time being in force.</div>
                                        <div className='pgTxtNormal'>Thereafter, within a reasonable period (generally not exceeding 90 [ninety] days), we will either delete your personal information or de-identify it so that it is anonymous and not attributed to your identity. For example, we may retain certain information to prevent, investigate, or identify possible wrongdoing in connection with the Website or to comply with legal obligations.</div>
                                        <div className='pgTxtBold'>Contact us</div>
                                        <div className='pgTxtNormal'>If you have any queries or grievances relating to the processing/usage of information provided by you or the Policy, you may Email us at <Link to="mailto:sscsb.support@gmail.com">sscsb.support@gmail.com.</Link>.</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;