import React from 'react';

const AboutSSCSB = () => {
    return (
        <div className='padBot20'>
            <div className='aboutUs'>
                <div className='pgTxtBold'>Message from the President's Desk</div>
                <div className='pgTxtNormal'>Dear Devotees and Friends,</div>
                <div className='pgTxtNormal'>It is with great humility and profound gratitude that I assume the responsibility of serving the Assamese Community in Bangalore as their President of <b>Bangalore Naamghar</b>, a spiritual initiative of the <b>Sreemanta Sankardeva Cultural Society, Bangalore (SSCSB)</b>. I want to begin by expressing my deepest thanks to each one of you for placing your trust in me and for your unwavering support throughout the process of forming the new Committee. Your confidence in my leadership is a tremendous source of inspiration, and I am truly humbled by the responsibility you have entrusted to me.</div>
                <div className='pgTxtNormal'>First and foremost, I would like to extend my heartfelt appreciation to our outgoing President, Mr. Munindra Kumar Bharatee. Under his exceptional leadership, we have seen our organization grow, thrive, and make a significant impact in our community. Mr Bharatee’s vision, dedication, and tireless efforts have set a high standard for all of us, and I am committed to building upon the strong foundation he has established. During this tenure, we have been privileged to witness extraordinary contributions from members and partners alike, each playing a vital role in the success of our programs and initiatives. From inspiring events including highest Xorai offerings ever and cultural celebrations to community outreach in terms of membership registration and educational endeavours, his enthusiasm and commitment has been the driving force behind our achievements.</div>
                <div className='pgTxtNormal'>As I assume this role, I am keenly aware of the immense responsibility it carries. Our organization stands as a beacon of socio-cultural-spiritual enrichment, unity, and community service, and it is my solemn pledge to uphold the values that have guided us thus far. I am excited about the opportunities that lie ahead, and I look forward to working with each of you to continue our mission and explore new avenues for growth and development.</div>
                <div className='pgTxtNormal'>In the coming months, we will focus on several key priorities:</div>
                <ul className="bulletPoints_un">
                    <li className="bp_circleOutline"><b>Naamghar Infrastructure Development:</b> Our first and foremost priority is to regularise our land premises and ensuring that we are compliant and regulated in all perspectives. Considerable work has been done by the previous committee and we hope to take this initiative into a logical conclusion of registering the premises and building an infrastructure for the future. We shall keep you posted from time to time and would approach the community as large at an opportune time to seek your support in developing the infrastructure.</li>
                    <li className="bp_circleOutline"><b>Strengthening Community Connections:</b> We will seek to deepen our relationships within the community, expanding our outreach efforts to engage more individuals and Community organizations in our activities. Steps are already initiated to promote our activities and programs at a larger scale. Together, we will create meaningful opportunities for cultural exchange and collaboration. One of the steps undertaken to address outreach is to make access to our Naamghar activities easier by revamping and redesigning our online presence completely. That involves our website which would facilitate online registration of Xorai, Membership and would also provide real time updates of the various events and programs.</li>
                    <li className="bp_circleOutline"><b>Innovating Our Programs and Initiatives:</b> Our organization has always been a leader in Bangalore promoting cultural awareness and diversity be it Xatriya Nritya, Diha Naam or even the teachings of Mahapurush Shankadev. We will build on our past successes and introduce innovative programs that reflect the evolving needs and interests of our members and the community at large. Our cultural team would firm up initiatives to involve children by teaching them Naam, Prarthana, and even Xatria dances. These are in the pipeline, and we hope to make a significant stride in this front with the involvement of the Assamese community at large. This is your platform and the community can make it a success by involving, engaging, participating and contributing with the Society whole heartedly.  </li>
                    <li className="bp_circleOutline"><b>Fostering Inclusivity and Participation:</b> It is essential that every voice is heard and every contribution is valued. We will work to ensure that our initiatives are inclusive and that everyone has the opportunity to be actively involved in our programs and decision-making processes. Towards that effect, we would reassess our membership programs and make it inclusive and accessible for all.</li>
                    <li className="bp_circleOutline"><b>Sustaining Our Legacy:</b> We will honour our rich history by preserving the traditions that have brought us to where we are today while embracing new ideas and approaches to ensure our continued relevance and success. Apart from the Bihu Celebrations, Xorai during Maagh Month, Rajohuwa Naam, Bhaouna, Rakh Leela, we will explore newer activities like the Mahapurush Shankdev Mahotsav at Bangalore, Paper presentations, Seminars on the teachings of Mahapurush Shankardev and more.</li>
                    <li className="bp_circleOutline"><b>Compliance and Governance:</b> It is a key element of any Organisation, and we will take it upon ourselves to ensure that we are compliant in all the matters of Finance, Legal, Societal Registrations and explore the possibility of availing various tax exemptions that will benefit donors, etc.</li>
                    <li className="bp_circleOutline"><b>Community Service:</b> We will explore various avenues how we can expand our horizon in community services. During the Covid Pandemic, significant strides were made in supporting the Community. We will take a leaf out of our current activities like Bhog/ Anna Daan and institutionalise programs that will help the youth in terms of career, jobs, insurance, skills, employment disputes, etc. These are in the pipeline, and we hope to initiative them in a phased manner.   </li>
                </ul>
                <div className='pgTxtNormal'>While I have outlined the key areas that we would focus on, it is also important to mention a few words about our annual souvenir. On the occasion of our new souvenir getting published on the 6th Foundation Day, I would like to congratulate the team for working tirelessly to have our Souvenir collated, compiled and curated for all our us in record time. This annual souvenir stands as a testament to the vibrant tapestry of our collective efforts, passion, and dedication. Through the pages of this cherished publication, we reflect upon the milestones we have achieved, the memories we have created, and the impact we have made within our community. </div>
                <div className='pgTxtNormal'>As we look back on this year’s journey, let us also look forward with renewed hope and determination. The challenges we face today are opportunities for growth and innovation, and together, we will continue to build a future where culture and community thrive. In this edition of our souvenir, you will find highlights of our endeavours, reflections on our experiences, and glimpses into the future we are shaping together. I encourage you to take pride in the stories shared and the achievements celebrated, for they are a reflection of your dedication and hard work.</div>
                <div className='pgTxtNormal'>I thank you once again, for being an integral part of our community. Your support, enthusiasm, and unwavering belief in our mission inspire us every day. As we celebrate last year’s successes, let us move forward with optimism and a shared vision for what we can accomplish together. </div>
                <div className='pgTxtNormal'>I am deeply inspired by the dedication and passion of our members, and I am eager to embark on this journey with all of you. Together, we will strive to create a future where our organization not only maintains its role as a socio-cultural-spiritual leader but also explores new horizons and achieves new milestones. I am excited to collaborate with all of you, to learn from your experiences, and to contribute to the vibrant future of Sreemanta Sankardeva Cultural Society, Bangalore (SSCSB).</div>
                <div className='pgTxtNormal'>Thank you once again for this incredible opportunity. Let us embrace this new chapter with enthusiasm and commitment and let us work together to make a lasting impact.</div>
                <div className='pgTxtNormal'>Thank you.<br />Chandan Kumar Sonowal<br /><b>President</b><br /><b>Sreemanta Sankardeva Cultural Society, Bangalore (SSCSB).</b>
                </div>
            </div>
        </div >
    );
};

export default AboutSSCSB;
