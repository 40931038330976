export const events = [
    // { date: new Date(2024, 6, 7), title: 'Rajohuwa Naam' },
    // { date: new Date(2024, 6, 21), title: 'Pratistha Diwas' },
    { date: new Date(2024, 7, 4), title: 'Rajohuwa Naam' },
    { date: new Date(2024, 7, 18), title: 'First Bhado Maah Naam' },
    { date: new Date(2024, 7, 24), title: 'Mahapurush Madhavdev Tirubhav Tithi' },
    { date: new Date(2024, 7, 25), title: 'Janmashtami Cultural Program' },
    { date: new Date(2024, 7, 26), title: 'Sri Sri Krishna Janmashtami' },
    { date: new Date(2024, 8, 1), title: 'Rajohuwa Naam' },
    { date: new Date(2024, 8, 4), title: 'Mahapurush Srimanta Sankardeva Tirubhav Tithi' },
    { date: new Date(2024, 8, 17), title: 'Bhado Maah Naam Xamoroni' },
    { date: new Date(2024, 9, 6), title: 'Rajohuwa Naam' },
    { date: new Date(2024, 10, 3), title: 'Rajohuwa Naam' },
    { date: new Date(2024, 11, 1), title: 'Rajohuwa Naam' },
];