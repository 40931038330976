import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

const Header = () => {
    const [dropdown, setDropdown] = useState(null);
    const navigate = useNavigate();

    // Handle dropdown toggle on hover
    const handleDropdown = (index) => {
        setDropdown(dropdown === index ? null : index);
    };

    const handleLogout = () => {
        console.log('Logged out');
        navigate('/admin'); // Navigate to login page after logout
    };

    return (
        <header className="adminHeader">
            <nav className="navbar">
                <ul className="nav-items">
                    <li
                        className="nav-item"
                        onMouseEnter={() => handleDropdown(0)}
                        onMouseLeave={() => handleDropdown(null)}
                    >
                        <Link to="/members">Members</Link>
                        {/* {dropdown === 0 && (
                            <ul className="dropdown">
                                <li className="dropdown-item">Sub Item 1</li>
                                <li className="dropdown-item">Sub Item 2</li>
                            </ul>
                        )} */}
                    </li>

                    <li
                        className="nav-item"
                        onMouseEnter={() => handleDropdown(1)}
                        onMouseLeave={() => handleDropdown(null)}
                    >
                        <Link to="/xorai">Xorai</Link>
                        {/* {dropdown === 1 && (
                            <ul className="dropdown">
                                <li className="dropdown-item">Sub Item 1</li>
                                <li className="dropdown-item">Sub Item 2</li>
                            </ul>
                        )} */}
                    </li>

                    <li
                        className="nav-item"
                        onMouseEnter={() => handleDropdown(2)}
                        onMouseLeave={() => handleDropdown(null)}
                    >
                        <Link to="/donation">Donation</Link>
                        {/* {dropdown === 2 && (
                            <ul className="dropdown">
                                <li className="dropdown-item">Sub Item 1</li>
                                <li className="dropdown-item">Sub Item 2</li>
                            </ul>
                        )} */}
                    </li>

                    <li
                        className="nav-item"
                        onMouseEnter={() => handleDropdown(3)}
                        onMouseLeave={() => handleDropdown(null)}
                    >
                        <Link to="/events">Events</Link>
                        {/* {dropdown === 3 && (
                            <ul className="dropdown">
                                <li className="dropdown-item">Sub Item 1</li>
                                <li className="dropdown-item">Sub Item 2</li>
                            </ul>
                        )} */}
                    </li>

                    <li
                        className="nav-item"
                        onMouseEnter={() => handleDropdown(4)}
                        onMouseLeave={() => handleDropdown(null)}
                    >
                        <Link to="/home-slider">Homepage</Link>
                        {dropdown === 4 && (
                            <ul className="dropdown">
                                <li className="dropdown-item">Slider</li>
                            </ul>
                        )}
                    </li>
                </ul>

                <button className="logout-btn" onClick={handleLogout}>
                    Logout
                </button>
            </nav>
        </header>
    );
};

export default Header;
