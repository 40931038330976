import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CustomButton from '../../component/CustomButton';

const DonateForm = ({ isOpen, onClose }) => {
    const [amount, setAmount] = useState('');
    const [selectedDonationType, setSelectedDonationType] = useState('DEFAULT');

    if (!isOpen) return null;

    const handleAmountChange = (event) => {
        setAmount(event.target.value);
    };

    const handleDonationTypeChange = (event) => {
        setSelectedDonationType(event.target.value);
    };

    const formatAmount = (amount) => {
        return new Intl.NumberFormat('en-IN').format(amount);
    };

    return (
        <div className="overlay" onClick={onClose}>
            <div className="modalDonation" onClick={(e) => e.stopPropagation()}>
                <div className='modalClose'>
                    <button className='popCloseButton' onClick={onClose}>X</button>
                </div>
                <div className="popupDonateForm">
                    <div className='modalHead'>Make a Secure Donation</div>
                    <div className="donationFormContainer">
                        <form>
                            <div className='donateTypeAmtBlk'>
                                <ul>
                                    <li>
                                        <div className='labelWhite'>
                                            Donation Type:
                                            {selectedDonationType === "Anna Daan / Bhog" && (
                                                <span className='spclText' style={{ display: "none" }}><Link to="/">
                                                    Show Details
                                                </Link></span>
                                            )}
                                        </div>
                                        <div className="xoraiSelectField">
                                            <select
                                                name="filterType"
                                                value={selectedDonationType}
                                                onChange={handleDonationTypeChange}
                                                required
                                            >
                                                <option value="DEFAULT">Donation to Namghar</option>
                                                <option value="Anna Daan / Bhog">Anna Daan / Bhog</option>
                                                <option value="Rajohuwa Naam">Rajohuwa Naam</option>
                                                <option value="Janmastami Celebrations">Janmastami Celebrations</option>
                                                <option value="Bhado Maah Naam Kirtan">Bhado Maah Naam Kirtan</option>
                                                <option value="Monthly Maintenance of Naamghar">Monthly Maintenance of Naamghar</option>
                                            </select>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='labelWhite'>Amount:</div>
                                        <div className='rupeeIcon'></div>
                                        <input
                                            type="number"
                                            id="amount"
                                            value={amount}
                                            onChange={handleAmountChange}
                                            className='no-spinner'
                                        />
                                    </li>
                                </ul>
                            </div>

                            <div className='donateFormIIcol'>
                                <ul className='donateFormIIcol'>
                                    <li>
                                        <label htmlFor="name">Name:</label>
                                        <div className="userIcon"></div>
                                        <input
                                            type="text"
                                            id="name"
                                        />
                                    </li>
                                    <li>
                                        <label htmlFor="email">Email:</label>
                                        <div className="mailIcon"></div>
                                        <input
                                            type="email"
                                            id="email"
                                        />
                                    </li>
                                    <li>
                                        <label htmlFor="phone">Phone:</label>
                                        <div className="phIcon"></div>
                                        <div className="getOtpBtnBlk">
                                            <input type="button" className="getOtpBtn" value="Get OTP" />
                                        </div>
                                        <input
                                            type="number"
                                            id="phone"
                                            className='no-spinner'
                                        />
                                    </li>
                                    <li>
                                        <label htmlFor="otp">OTP:</label>
                                        <div className="otpIcon"></div>
                                        <input
                                            type="number"
                                            id="otp"
                                            className='no-spinner'
                                        />
                                    </li>
                                </ul>
                                <ul className='donateFormIcol'>
                                    <li>
                                        <label htmlFor="address">Address:</label>
                                        <div className="locationIcon"></div>
                                        <input
                                            type="text"
                                            id="address"
                                        />
                                    </li>
                                </ul>
                                <div className='buttonLabel'>
                                    <CustomButton type="submit" value={`Continue to Pay ${amount && ` ₹${formatAmount(amount)}`}`} css="buttonDonate" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DonateForm;
