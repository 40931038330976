import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const LoginPage = () => {
    const [isOtpLogin, setIsOtpLogin] = useState(false);
    const [otpRequested, setOtpRequested] = useState(false);
    const [otpTimer, setOtpTimer] = useState(0);  // Timer starts at 0
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();

    // Toggle OTP login on and off
    const handleToggle = () => {
        setIsOtpLogin((prevState) => !prevState);  // Toggle the isOtpLogin state
        setOtpRequested(false);  // Reset OTP request status
        setOtpTimer(0);  // Reset the timer
        setOtp('');  // Clear OTP input
    };

    const handleRequestOtp = () => {
        setOtpRequested(true);
        setOtpTimer(30);  // Start 30 sec timer
        const timer = setInterval(() => {
            setOtpTimer((prev) => {
                if (prev === 1) {
                    clearInterval(timer);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
    };

    const handleSubmitOtp = () => {
        console.log('OTP Submitted:', otp);
    };

    const handleOtpAction = () => {
        if (otpRequested && otpTimer > 0) {
            handleSubmitOtp();  // If OTP is requested and timer is active, submit OTP
        } else {
            handleRequestOtp();  // Else request a new OTP
        }
    };

    useEffect(() => {
        if (otpTimer === 0) {
            setOtpRequested(false);  // Allow resending OTP after the timer ends
        }
    }, [otpTimer]);

    return (
        <div className="loginPageContainer">
            <div className="loginPage">
                <div className="logo">
                    <Link to="/">
                        <div className="sscsbLogo logoPosition"></div>
                    </Link>
                </div>
                <h2>SSCSB Admin Login</h2>

                {/* Toggle OTP section */}
                <div className="toggleOtpEmail">

                    <label className="toggle-switch">
                        <input
                            type="checkbox"
                            id="otpSwitch"
                            checked={isOtpLogin}
                            onChange={handleToggle}  // Handle toggle state change
                        />
                        <span className="slider"></span>
                    </label>
                    <label htmlFor="otpSwitch">Login with OTP</label>
                </div>

                <div className="form-container">
                    <div className="input-field">
                        <label>Email Address</label>
                        <input type="email" placeholder="Enter your email" />
                    </div>

                    {isOtpLogin && (
                        <>
                            {otpRequested && (
                                <div className="input-field">
                                    <label>Enter OTP</label>
                                    <input
                                        type="text"
                                        placeholder="Enter OTP"
                                        value={otp}
                                        onChange={(e) => setOtp(e.target.value)}
                                    />
                                </div>
                            )}

                            <button className="btn" onClick={handleOtpAction}>
                                {!otpRequested ? 'Send OTP' : (otpTimer > 0 ? 'Submit OTP' : 'Resend OTP')}
                            </button>

                            {otpRequested && otpTimer > 0 && (
                                <p className="timer">Resend OTP in {otpTimer} seconds</p>
                            )}
                        </>
                    )}

                    {!isOtpLogin && (
                        <>
                            <div className="input-field">
                                <label>Password</label>
                                <input type="password" placeholder="Enter your password" />
                            </div>
                            <button className="btn" onClick={() => navigate('/adminmemberlist')}>Login</button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
