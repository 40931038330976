import React, { useState, useEffect } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import CustomButton from '../../component/CustomButton';
import { Link } from 'react-router-dom';

const XoraiForm = () => {
    const [familyMembers, setFamilyMembers] = useState('');
    const [bhokots, setBhokots] = useState('');
    const [offer, setOffer] = useState('DEFAULT');
    const [specialRequest, setSpecialRequest] = useState('DEFAULT');
    const [amount, setAmount] = useState(1520); // initial value based on Orihona + FixedExpense
    const [formStarted, setFormStarted] = useState(false);
    const [bhogExpense, setBhogExpense] = useState(0);
    const [showQRCode, setShowQRCode] = useState(false); // QR code visibility state

    const Orihona = 500;
    const FixedExpense = 820;
    const baseAmount = Orihona + FixedExpense;
    const VariableExpense = 75;
    const JolpanExpense = 100;
    const PerPlateLessThan31 = 110;
    const PerPlateMoreThan31 = 80;
    const cookingCost = 1250;
    const additionalCookingCost = 400;

    const accountNumber = 'SREEMANTASANKARDEVA@rbl';
    const name = 'Bangalore Naamghar'; // Name to display
    const transactionNote = 'Payment for Xorai at Bangalore Naamghar'; // Payment note

    const formatAmount = (amount) => {
        return new Intl.NumberFormat('en-IN').format(amount);
    };

    const calculateTotalCount = () => {
        return (parseInt(familyMembers) || 0) + (parseInt(bhokots) || 0);
    };

    const calculateMahProkhadExpense = (totalCount) => {
        return VariableExpense * totalCount;
    };

    const calculateBhogJolpanExpense = (totalCount) => {
        return JolpanExpense * totalCount;
    };

    const generatePaymentLink = () => {
        return `upi://pay?pa=${accountNumber}&pn=${encodeURIComponent(name)}&tn=${encodeURIComponent(transactionNote)}&am=${amount}&cu=INR`;
    };

    // const handleQRCodePay = () => {
    //     setShowQRCode(true); // Show QR code when button is clicked
    // };

    const handleQRCodePay = (event) => {
        event.preventDefault(); // Prevent form submission if inside a form
        setShowQRCode(true); // Show QR code when button is clicked
    };

    useEffect(() => {
        // if (offer === 'Xorai') {
        //     setSpecialRequest('DEFAULT');
        // }
        if (offer === 'Xorai' || offer === 'XoraiOwnItems') {
            setSpecialRequest('DEFAULT');
        }

        const totalCount = calculateTotalCount();
        let newAmount = baseAmount; // base amount
        let calculatedBhogExpense = 0;

        if (offer === 'Xorai') {
            newAmount += totalCount * VariableExpense;
        } else if (offer === 'Xorai & Jalpan') {
            newAmount += totalCount * JolpanExpense;
        } else if (offer === 'Xorai & Bhog') {
            if (totalCount < 31) {
                calculatedBhogExpense = (totalCount * PerPlateLessThan31) + (totalCount * VariableExpense);
            } else if (totalCount >= 31 && totalCount < 51) {
                calculatedBhogExpense = (totalCount * PerPlateMoreThan31) + (totalCount * VariableExpense) + cookingCost;
            } else if (totalCount >= 51 && totalCount < 300) {
                calculatedBhogExpense = (totalCount * PerPlateMoreThan31) + (totalCount * VariableExpense) + cookingCost + additionalCookingCost;
            }
            newAmount += calculatedBhogExpense;
            setBhogExpense(calculatedBhogExpense); // update bhogExpense state
        } else if (offer === 'XoraiOwnItems') {
            // Logic for 'Xorai (Own Items)' if needed
        }

        if (specialRequest === 'Sweets') {
            newAmount += totalCount * 20;
        } else if (specialRequest === 'Payaas') {
            newAmount += totalCount * 30;
        }

        console.log(`Total Count: ${totalCount}, New Amount: ${newAmount}`);
        setAmount(newAmount);
    }, [familyMembers, bhokots, offer, specialRequest]);

    const handleInputChange = (setter) => (e) => {
        setFormStarted(true);
        setter(e.target.value);
    };

    const totalCount = calculateTotalCount();
    const mahProkhadExpense = calculateMahProkhadExpense(totalCount);
    const bhogJolpanExpense = calculateBhogJolpanExpense(totalCount);

    return (
        <div className="xoraiFormContainer">
            <form>
                <ul className='xoraiFormIIIcol'>
                    <li className='xoraiFormIIIcol'>
                        <label htmlFor="name">Name:</label>
                        <div className="userIcon"></div>
                        <input
                            type="text"
                            id="name"
                            onChange={handleInputChange(() => { })}
                        />
                    </li>
                    <li className='xoraiFormIIIcol'>
                        <label htmlFor="phone">Phone:</label>
                        <div className="phIcon"></div>
                        <div className="getOtpBtnBlk">
                            <input type="button" className="getOtpBtn" value="Get OTP" />
                        </div>
                        <input
                            type="text"
                            id="phone"
                            className="no-spinner"
                            onChange={handleInputChange(() => { })}
                        />
                    </li>
                    <li className='xoraiFormIIIcol'>
                        <label htmlFor="otp">OTP:</label>
                        <div className="otpIcon"></div>
                        <input
                            type="text"
                            id="otp"
                            className="no-spinner"
                            onChange={handleInputChange(() => { })}
                        />
                    </li>
                </ul>
            </form>
            <ul className='xoraiAmountVerBlk'>
                {/* Xorai Form */}
                <li className='xoraiAmountVerBlk'>
                    <form>
                        <ul className='xoraiFormIIcol'>
                            <li className='xoraiFormIIcol'>
                                <div className="infoIcon">
                                    <span className="tooltipText">Please select Xorai (Arrange own Items), if you intend to bring your own items for Bhog, Prokhad or Jolpan</span>
                                </div>
                                <label htmlFor="offer">What do you want to offer:</label>
                                <div className="xoraiIcon"></div>
                                <div className="xoraiSelectField">
                                    <select
                                        name="filterType"
                                        value={offer}
                                        onChange={handleInputChange(setOffer)}
                                        required
                                    >
                                        <option value="DEFAULT" disabled>Select</option>
                                        <option value="Xorai">Xorai</option>
                                        <option value="Xorai & Jalpan">Xorai with Jalpan</option>
                                        <option value="Xorai & Bhog">Xorai with Bhog</option>
                                        <option value="XoraiOwnItems">Xorai (Arrange own Items)</option>
                                    </select>
                                </div>
                            </li>
                            <li className='xoraiFormIIcol'>
                                <label htmlFor="specialRequest">Any special request to offer:</label>
                                <div className="requestIcon"></div>
                                <div className={`xoraiSelectField ${offer === 'Xorai' || offer === 'XoraiOwnItems' ? 'disabled' : ''}`}>
                                    <select
                                        name="filterType"
                                        value={specialRequest}
                                        onChange={handleInputChange(setSpecialRequest)}
                                        disabled={offer === 'Xorai' || offer === 'XoraiOwnItems'}
                                        required
                                    >
                                        <option value="DEFAULT">Select</option>
                                        <option value="Sweets">Sweets</option>
                                        <option value="Payaas">Payaas</option>
                                    </select>
                                </div>
                            </li>
                            <li className='xoraiFormIIcol'>
                                <div className="infoIcon">
                                    <span className="tooltipText">Please include the no. of people you expect (include family members and guests)</span>
                                </div>
                                <label htmlFor="familyMember">No. of people:</label>
                                <div className="foodIcon"></div>
                                <input
                                    type="text"
                                    id="familyMember"
                                    className="no-spinner"
                                    value={familyMembers}
                                    onChange={handleInputChange(setFamilyMembers)}
                                />
                            </li>
                            <li className='xoraiFormIIcol'>
                                <label htmlFor="bhokot">
                                    No. of bhokot invited:
                                    <div className='labelRightLink'>
                                        <Link to="/bhokot">Bhokot List</Link>
                                    </div>
                                </label>
                                <div className="bhokotIcon"></div>
                                <input
                                    type="text"
                                    id="bhokot"
                                    className="no-spinner"
                                    value={bhokots}
                                    onChange={handleInputChange(setBhokots)}
                                />
                            </li>
                            <li className='xoraiFormIIcol'>
                                <label htmlFor="totalCount">Total count:</label>
                                <div className="noIcon"></div>
                                <input
                                    type="text"
                                    id="totalCount"
                                    value={totalCount}
                                    readOnly
                                />
                            </li>
                            <li className='xoraiFormIIcol' style={{ display: "none" }}>
                                <label htmlFor="amount">Amount:</label>
                                <div className="msgIcon"></div>
                                <input
                                    type="text"
                                    id="amount"
                                    value={formatAmount(amount)}
                                    readOnly
                                />
                            </li>
                        </ul>
                        <ul className='xoraiFormIIcol'>
                            <li className='xoraiFormIIcol'>
                                <CustomButton
                                    type="button"
                                    value={`Pay ${formStarted ? `₹${formatAmount(amount)}` : ''} by Card`}
                                    css="buttonRed100pc"
                                />
                            </li>
                            <li className='xoraiFormIIcol'>
                                <input
                                    type="button"
                                    className="buttonRed100pc"
                                    value={`Pay ${formStarted ? `₹${formatAmount(amount)}` : ''} by QR Scanner`}
                                    onClick={handleQRCodePay}
                                />
                            </li>
                        </ul>
                        <div className='buttonLabel' style={{ display: "none" }}>
                            <CustomButton
                                type="button"
                                value={`Pay ${formStarted ? `₹${formatAmount(amount)}` : ''}`}
                                css="buttonRed"
                            />

                            <input
                                type="button"
                                className="buttonRed"
                                value={`QR Scanner`}
                                onClick={handleQRCodePay}
                            />
                        </div>
                    </form>
                    {/* QR Code Display */}
                    {showQRCode && (
                        <ul className='xoraiQRCodeBlk'>
                            <li className='xoraiQRCode'>
                                <QRCodeCanvas value={generatePaymentLink()} />
                            </li>
                            <li className='xoraiQRForm'>

                                <div className='xoraiFormIcol'>
                                    <label htmlFor="transactionid">Transaction ID:</label>
                                    <div className="noIcon"></div>
                                    <input
                                        type="text"
                                        id="transactionid"
                                    />
                                </div>
                                <div className='xoraiFormIcol'>
                                    <div className='buttonLabel'>
                                        <CustomButton
                                            type="submit"
                                            value={`Submit`}
                                            css="buttonRed"
                                        />
                                    </div>
                                </div>


                            </li>
                        </ul>
                    )}
                </li>

                {/* Amount Description */}
                <li className='xoraiAmountVerBlk'>
                    <div className='xoraiAmountHead'>
                        Amount Breakup
                    </div>
                    <ul className='xoraiAmountBreakup'>
                        <li className='xoraiAmountBreakup'>
                            Naamghar Orihona
                        </li>
                        <li className='xoraiAmountBreakup'>
                            ₹{Orihona}
                        </li>
                        <li className='xoraiAmountBreakup'>
                            Fixed Expense
                            <div className="infoIcon">
                                <span className="tooltipText">Miscellaneous pooja items, utility costs, housekeeping, maintenance costs, etc.</span>
                            </div>
                        </li>
                        <li className='xoraiAmountBreakup'>
                            ₹{FixedExpense}
                        </li>
                        {offer === 'Xorai' && (
                            <>
                                <li className='xoraiAmountBreakup'>
                                    Xorai
                                </li>
                                <li className='xoraiAmountBreakup'>
                                    ₹{formatAmount(totalCount * VariableExpense)}
                                </li>
                            </>
                        )}
                        {offer === 'Xorai & Jalpan' && (
                            <>
                                <li className='xoraiAmountBreakup'>
                                    Xorai with Jolpan
                                </li>
                                <li className='xoraiAmountBreakup'>
                                    ₹{formatAmount(totalCount * JolpanExpense)}
                                </li>
                            </>
                        )}
                        {offer === 'Xorai & Bhog' && (
                            <>
                                <li className='xoraiAmountBreakup'>
                                    Xorai with Bhog
                                </li>
                                <li className='xoraiAmountBreakup'>
                                    ₹{formatAmount(bhogExpense)}
                                </li>
                            </>
                        )}
                        {specialRequest === 'Sweets' && (
                            <>
                                <li className='xoraiAmountBreakup'>
                                    Sweets
                                </li>
                                <li className='xoraiAmountBreakup'>
                                    ₹{formatAmount(totalCount * 20)}
                                </li>
                            </>
                        )}
                        {specialRequest === 'Payaas' && (
                            <>
                                <li className='xoraiAmountBreakup'>
                                    Payaas
                                </li>
                                <li className='xoraiAmountBreakup'>
                                    ₹{formatAmount(totalCount * 30)}
                                </li>
                            </>
                        )}
                        <li className='xoraiAmountBreakup'>
                            Total Expense
                        </li>
                        <li className='xoraiAmountBreakup'>
                            ₹{formatAmount(amount)}
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    );
};

export default XoraiForm;
