import CustomButton from "../../../../component/CustomButton";

const tableData = {
    headers: ['Name', 'Member From', 'Renewal Date', 'Member Type', 'Action'],
    rows: [
        // ['Monangku Hazarika', '2024-06-30', '2024-06-30', 'Annual Member', ''],
        // ['Santosh Nath', '2024-06-30', '2024-07-15', 'Associate Member', <CustomButton type="submit" value="Pay" css="btnRed_M" />],
        // ['Ganesh Gogoi', '2024-06-30', '', 'Lifetime Member', ''],
        // ['Simanta Deka', '2024-06-30', '2024-06-25', 'Annual Member', <CustomButton type="submit" value="Renew" css="btnRed_M" />],
        ['Shri Vijay Kumar Reddy', '2020-01-01', '', 'Honorary Member', ''],
    ],
    membershipTypeIndex: 3, // Index of the membership type column
    actionColumnIndex: 4, // Index of the action column
};

export default tableData;

