import React from 'react';
import ExecutiveMemberData from '../../../components/executiveMemberData'

const Table = ({ data }) => {
    return (
        <div className='executiveComiteeTable'>
            <table>
                <thead>
                    <tr>
                        <th>Sl No</th>
                        <th>Name</th>
                        <th>Position</th>
                        <th>Phone Number</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.position}</td>
                            <td>{item.phone}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const ExecutiveCommittee = () => {
    return (
        <div className='padBot20'>
            <div className='aboutUs'>
                <div className='pgTxtBold'>Executive Committee 2024-2026</div>
                <Table data={ExecutiveMemberData} />
            </div>

        </div>
    );
};

export default ExecutiveCommittee;
