import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { events } from '../components/xoraiData'; // Import xoraidata

const MyCalendar = ({ date, OnDateChange }) => {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(date);

    // Filter dates based on event type
    const xoraiDates = events.filter(event => event.type === 'xoraiDate').map(event => event.date);
    const disableDates = events.filter(event => event.type === 'disableDate').map(event => event.date);

    // Handle date selection
    const onChange = (newDate) => {
        OnDateChange(newDate);
        setSelectedDate(newDate);
        setCurrentMonth(new Date(newDate.getFullYear(), newDate.getMonth(), 1));
    };

    // Handle month navigation
    const onActiveDateChange = ({ activeStartDate }) => {
        setCurrentMonth(new Date(activeStartDate.getFullYear(), activeStartDate.getMonth(), 1));
    };

    useEffect(() => {
        console.log('Current Month Updated:', currentMonth); // Debugging line
    }, [currentMonth]);

    // const tileClassName = ({ date, view }) => {
    //     if (view === 'month') {
    //         const isXoraiDate = xoraiDates.some(xoraiDate => xoraiDate.toDateString() === date.toDateString());
    //         const isDisableDate = disableDates.some(disableDate => disableDate.toDateString() === date.toDateString());
    //         const isSelectedDate = selectedDate.toDateString() === date.toDateString();

    //         if (isSelectedDate) {
    //             return 'react-calendar__tile--selected';
    //         }
    //         if (isXoraiDate) {
    //             return 'react-calendar__tile--xorai-date';
    //         }
    //         if (isDisableDate) {
    //             return 'react-calendar__tile--disable-date';
    //         }
    //     }
    //     return null;
    // };

    const tileClassName = ({ date, view }) => {
        if (view === 'month') {
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Normalize today's date

            const isXoraiDate = xoraiDates.some(xoraiDate => xoraiDate.toDateString() === date.toDateString());
            const isDisableDate = disableDates.some(disableDate => disableDate.toDateString() === date.toDateString());
            const isSelectedDate = selectedDate.toDateString() === date.toDateString();
            const isPastDate = date < today;

            if (isSelectedDate) {
                return 'react-calendar__tile--selected';
            }
            if (isXoraiDate) {
                return 'react-calendar__tile--xorai-date';
            }
            if (isDisableDate || isPastDate) {
                return 'react-calendar__tile--disable-date';
            }
        }
        return null;
    };


    // const tileDisabled = ({ date, view }) => {
    //     if (view === 'month') {
    //         return disableDates.some(disabledDate => disabledDate.toDateString() === date.toDateString());
    //     }
    //     return false;
    // };

    const tileDisabled = ({ date, view }) => {
        if (view === 'month') {
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Normalize today's date to remove the time part

            // Disable dates before today and disable dates in the disableDates array
            return date < today || disableDates.some(disabledDate => disabledDate.toDateString() === date.toDateString());
        }
        return false;
    };


    // Filter events based on the current month
    const filteredEvents = events.filter(event =>
        event.date.getFullYear() === currentMonth.getFullYear() &&
        event.date.getMonth() === currentMonth.getMonth()
    );

    return (
        <div>
            <Calendar
                onChange={onChange}
                value={selectedDate}
                tileClassName={tileClassName}
                tileDisabled={tileDisabled}
                onActiveDateChange={onActiveDateChange} // Ensure month updates correctly
            />

            {/* Only display the events list if there are events */}
            {filteredEvents.length > 0 && (
                <ul className='xoraiEventDate'>
                    {filteredEvents.map((event, index) => (
                        <li key={index} className='xoraiEventDate'>
                            <div className="eventHead">{event.title}:</div>
                            <div className="eventDate">{event.date.toDateString()}</div>
                        </li>
                    ))}
                </ul>
            )}
            {filteredEvents.length === 0 && (
                <div className='noEventsMessage' style={{ display: "none" }}>No events for this month</div>
            )}
        </div>
    );
}

export default MyCalendar;
