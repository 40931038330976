import React from 'react';
import Container from "../../../container";
import MemberJoinForm from '../../../components/MemberJoinForm';
import { Link } from 'react-router-dom';

const Donate = () => {

    return (
        <>
            <Container headerType={'inner'}>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div className='memberFormBlk'>
                            <div className='blueBg'>
                                <div className='gradiantColorBlk'>
                                    <div className='widthfix'>
                                        <ul className='memberFormIIcol'>
                                            <li className='donateIIcol'>
                                                <div className='donatePgHead'>Membership</div>
                                                <div className='donateImage'></div>
                                                <div className='memberSubLine'>SSCSB Membership - A way to connect with SSCSB activities and Axomia Naamghor, Bangalore</div>

                                            </li>
                                            <li className='donateIIcol'>
                                                <div className='signInLink'>Please <Link to="#" onClick={() => window.fnSign(1)}>SignIn</Link> if you a registered member</div>
                                                <MemberJoinForm />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='gradiantColorBlk'>
                                <div className='widthfix'>
                                    <ul className='memberFormIIcol'>
                                        <li className='memberFormIIcol'>&nbsp;<br />&nbsp;<br />&nbsp;</li>
                                        <li className='memberFormIIcol'>&nbsp;<br />&nbsp;<br />&nbsp;</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Donate;