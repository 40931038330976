import React from 'react';
import { Link } from 'react-router-dom';

const MemberType = () => {

    return (
        <div>
            <div className='inPgHead'>Join the Community</div>
            <div className='inPgHeadDesc'>Your contribution can really help</div>
            <div className='membershipLink'>
                <Link to="/memberlist">List of Members</Link>  &nbsp;|&nbsp;  <Link to="/membership">Member Benefits</Link>
            </div>
        </div>
    );
};

export default MemberType;