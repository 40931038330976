import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const MemberType = ({ showFull }) => {
    const navigate = useNavigate();
    const [expandedBlock, setExpandedBlock] = useState(null);

    const handleDonateClick = () => {
        navigate('/memberform');
    };

    const toggleExpand = (blockId) => {
        setExpandedBlock(expandedBlock === blockId ? null : blockId);
    };

    return (
        <div className='membership'>
            <ul className='membershipIVcol'>
                {/* Associate Member */}
                <li>
                    <div className='memberParentBlk'>
                        <div className='memberIconBlk'>
                            <div className='memIcon4'></div>
                        </div>
                        <div className='memberType'>
                            Associate Member
                            <div className='memberFee'>₹ 250</div>
                            <div className='memberBtnBlk'>
                                <input type='button' className='joinButton' value="Join Now" onClick={handleDonateClick} ></input>
                            </div>
                        </div>

                        <div className='memberTypeDesc'>
                            An associate member is actively associated with SSCSB having access to all the communications and events of SSCSB/ Naamghar by paying a nominal annual fee and enjoys limited rights.
                        </div>
                        {showFull && (
                            <>
                                <input
                                    type='button'
                                    className='expandButton'
                                    value={expandedBlock === 1 ? "Collapse" : "Expand"}
                                    onClick={() => toggleExpand(1)}
                                />

                                {expandedBlock === 1 && (
                                    <div className="memberBlkContent">
                                        <div className="contentHead">Eligibility Critiera</div>
                                        <div className="contentNote">(Above 18 years for all categories)</div>
                                        <div className="eligibilityBlk">
                                            <div className="eligibilityPoints">
                                                Assamese, from Assam or any Non- Assamese associated with Assamese culture
                                            </div>
                                            <div className="eligibilityPoints">
                                                Should be based in Bengaluru
                                            </div>
                                            <div className="eligibilityPoints">Should be of sound character and repute</div>
                                        </div>

                                        <div className="contentHead">Validation / Verification </div>
                                        <div className="contentNormal">Should have Valid ID proof</div>
                                        <div className="contentHead">Approval Process</div>
                                        <div className="contentNormal">Apply and subject to approval</div>
                                        <div className="contentHead">Duration / Membership Type </div>
                                        <div className="contentNormal">Annual (from day of payment)</div>
                                        <div className="contentHead">Renewal </div>
                                        <div className="contentNormal">Required before end date</div>
                                        <div className="contentHead">Membership Fee</div>
                                        <div className="contentNormal">₹ 250</div>
                                        <div className="contentHead">Suspension / Validity</div>
                                        <div className="contentNormal">Membership will expire if it isn't renewed within 2 months of end date</div>
                                        <div className="contentHead">Forced Termination</div>
                                        <div className="contentNormal">On Any ANTI SSCSB Activities deemed by the Executive committee and ratified by the AGM / EGM</div>

                                        <div className="memberBenefitHead">Benefits</div>
                                        <div className="contentHead">Renewal Fee</div>
                                        <div className="contentNormal">₹ 250</div>
                                        <div className="contentHead">Monthly Maintenance Fee </div>
                                        <div className="contentNormal">Zero. Not adopted yet. A nominal fee can be adopted in due course of time. </div>
                                        <div className="contentHead">Dependent Member </div>
                                        <div className="contentNormal">None</div>
                                        <div className="contentHead">Transfer Membership</div>
                                        <div className="contentNormal">No</div>
                                        <div className="contentHead">Vote in SSCSB Elections</div>
                                        <div className="contentNormal">No</div>
                                        <div className="contentHead">Xorai Orihona</div>
                                        <div className="contentNormal">Required to pay</div>
                                        <div className="contentHead">Any Special Waivers</div>
                                        <div className="contentNormal">Request should be made to General Secretary (GS) of SSCSB and subject to approval by GS</div>
                                        <div className="contentHead">Run for Office / Member of EC</div>
                                        <div className="contentNormal">No</div>
                                        <div className="contentHead">Nomination to SAG </div>
                                        <div className="contentNormal">No</div>
                                        <div className="contentHead">Nomination to Sub Committee</div>
                                        <div className="contentNormal">Yes</div>
                                        <div className="contentHead">Nominated Term in SAG </div>
                                        <div className="contentNormal">Not Applicable</div>
                                        <div className="contentHead">Upgrade</div>
                                        <div className="contentNormal">To Regular Membership</div>
                                        <div className="contentHead">Naamghar Calendar</div>
                                        <div className="contentNormal">Yes</div>
                                        <div className="contentHead">Xasipaath </div>
                                        <div className="contentNormal">Yes</div>
                                        <div className="contentHead">Communication </div>
                                        <div className="contentNormal">Yes</div>
                                        <div className="contentHead">Invitation to Events</div>
                                        <div className="contentNormal">Yes</div>
                                        <div className="contentHead">Recognition </div>
                                        <div className="contentNormal">None</div>
                                        <div className="contentHead">Certificate of Honor</div>
                                        <div className="contentNormal">No</div>
                                        <div className="contentHead">Welcome Kit</div>
                                        <div className="contentNormal">Digital </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </li>
                {/* Regular Member */}
                <li>
                    <div className='memberParentBlk'>
                        <div className='memberIconBlk'>
                            <div className='memIcon3'></div>
                        </div>
                        <div className='memberType'>
                            Regular Member
                            <div className='memberFee'>₹ 1,000</div>
                            <div className='memberBtnBlk'>
                                <input type='button' className='joinButton' value="Join Now" onClick={handleDonateClick} ></input>
                            </div>
                        </div>
                        <div className='memberTypeDesc'>
                            Membership for a year by paying the stipulated annual membership fee and enjoys all the rights of a Regular Member. Have complete access to communications, events, resources, informations, etc.
                        </div>

                        {showFull && (
                            <>
                                <input
                                    type='button'
                                    className='expandButton'
                                    value={expandedBlock === 2 ? "Collapse" : "Expand"}
                                    onClick={() => toggleExpand(2)}
                                />

                                {expandedBlock === 2 && (
                                    <div className="memberBlkContent">
                                        <div className="contentHead">Eligibility Critiera</div>
                                        <div className="contentNote">(Above 18 years for all categories)</div>
                                        <div className="eligibilityBlk">
                                            <div className="eligibilityPoints">
                                                Assamese, from Assam or any Non- Assamese associated with Assamese culture
                                            </div>
                                            <div className="eligibilityPoints">
                                                Should be based in South India
                                            </div>
                                            <div className="eligibilityPoints">Should be of sound character and repute</div>
                                            <div className="eligibilityPoints">Non Bengalorean request is subject to approval by EC</div>
                                        </div>

                                        <div className="contentHead">Validation / Verification </div>
                                        <div className="contentNormal">Should have Valid ID proof</div>
                                        <div className="contentHead">Approval Process</div>
                                        <div className="contentNormal">Apply and subject to approval</div>
                                        <div className="contentHead">Duration / Membership Type </div>
                                        <div className="contentNormal">Annual (from day of payment)</div>
                                        <div className="contentHead">Renewal </div>
                                        <div className="contentNormal">Required before end date</div>
                                        <div className="contentHead">Membership Fee</div>
                                        <div className="contentNormal">₹ 1,000</div>
                                        <div className="contentHead">Suspension / Validity</div>
                                        <div className="contentNormal">Membership will expire if it isn't renewed within 2 months of end date</div>
                                        <div className="contentHead">Forced Termination</div>
                                        <div className="contentNormal">On Any ANTI SSCSB Activities deemed by the Executive committee and ratified by the AGM / EGM</div>

                                        <div className="memberBenefitHead">Benefits</div>
                                        <div className="contentHead">Renewal Fee</div>
                                        <div className="contentNormal">₹ 1,000</div>
                                        <div className="contentHead">Monthly Maintenance Fee </div>
                                        <div className="contentNormal">Zero. Not adopted yet. A nominal fee can be adopted in due course of time. </div>
                                        <div className="contentHead">Dependent Member </div>
                                        <div className="contentNormal">None</div>
                                        <div className="contentHead">Transfer Membership</div>
                                        <div className="contentNormal">No</div>
                                        <div className="contentHead">Vote in SSCSB Elections</div>
                                        <div className="contentNormal">Yes, Only One Vote</div>
                                        <div className="contentHead">Xorai Orihona</div>
                                        <div className="contentNormal">Free. One / Membership Year </div>
                                        <div className="contentHead">Any Special Waivers</div>
                                        <div className="contentNormal">Request should be made to General Secretary (GS) of SSCSB and subject to approval by GS</div>
                                        <div className="contentHead">Run for Office / Member of EC</div>
                                        <div className="contentNormal">Yes, should be a member for 6 months before nomination date</div>
                                        <div className="contentHead">Nomination to SAG </div>
                                        <div className="contentNormal">Yes, Nomination to 'Straegy and Advisory Group (SAG) on Approval at AGM by majority and subject to availability of vacant seat</div>
                                        <div className="contentHead">Nomination to Sub Committee</div>
                                        <div className="contentNormal">Yes</div>
                                        <div className="contentHead">Nominated Term in SAG </div>
                                        <div className="contentNormal">Two Terms</div>
                                        <div className="contentHead">Upgrade</div>
                                        <div className="contentNormal">To Life Membership</div>
                                        <div className="contentHead">Naamghar Calendar</div>
                                        <div className="contentNormal">Yes</div>
                                        <div className="contentHead">Xasipaath </div>
                                        <div className="contentNormal">Yes</div>
                                        <div className="contentHead">Communication </div>
                                        <div className="contentNormal">Yes</div>
                                        <div className="contentHead">Invitation to Events</div>
                                        <div className="contentNormal">Yes</div>
                                        <div className="contentHead">Recognition </div>
                                        <div className="contentNormal">None</div>
                                        <div className="contentHead">Certificate of Honor</div>
                                        <div className="contentNormal">No</div>
                                        <div className="contentHead">Welcome Kit</div>
                                        <div className="contentNormal">Digital </div>
                                    </div>
                                )}
                            </>

                        )}
                    </div>
                </li>
                {/* Lifetime Member */}
                <li>
                    <div className='memberParentBlk'>
                        <div className='memberIconBlk'>
                            <div className='memIcon2'></div>
                        </div>
                        <div className='memberType'>
                            Lifetime Member
                            <div className='memberFee'>₹ 15,000</div>
                            <div className='memberBtnBlk'>
                                <input type='button' className='joinButton' value="Join Now" onClick={handleDonateClick} ></input>
                            </div>
                        </div>
                        <div className='memberTypeDesc'>
                            Membership for whole lifetime and and enjoys all the rights of a Regular Member. Have complete access to communications, events, resources, informations, etc. By evaluation and invitation only.
                        </div>
                        {showFull && (
                            <>
                                {/* <input
                                    type='button'
                                    className='expandButton'
                                    value={isExpanded ? "Collapse" : "Expand"}
                                    onClick={toggleExpand}
                                />

                                {isExpanded && ( */}
                                <input
                                    type='button'
                                    className='expandButton'
                                    value={expandedBlock === 3 ? "Collapse" : "Expand"}
                                    onClick={() => toggleExpand(3)}
                                />

                                {expandedBlock === 3 && (
                                    <div className="memberBlkContent">
                                        <div className="contentHead">Eligibility Critiera</div>
                                        <div className="contentNote">(Above 18 years for all categories)</div>
                                        <div className="eligibilityBlk">
                                            <div className="eligibilityPoints">
                                                Assamese, from Assam or any Non- Assamese associated with Assamese culture
                                            </div>
                                            <div className="eligibilityPoints">
                                                Should be based in South India
                                            </div>
                                            <div className="eligibilityPoints">Should be of sound character and repute</div>
                                            <div className="eligibilityPoints">Non Bengalorean request is subject to approval by EC</div>
                                        </div>

                                        <div className="contentHead">Validation / Verification </div>
                                        <div className="contentNormal">Should have Valid ID proof</div>
                                        <div className="contentHead">Approval Process</div>
                                        <div className="contentNormal">By invitation only</div>
                                        <div className="contentHead">Duration / Membership Type </div>
                                        <div className="contentNormal">Lifetime</div>
                                        <div className="contentHead">Renewal </div>
                                        <div className="contentNormal">Auto Renewed</div>
                                        <div className="contentHead">Membership Fee</div>
                                        <div className="contentNormal">₹ 15,000</div>
                                        <div className="contentHead">Suspension / Validity</div>
                                        <div className="contentNormal">Not Applicable. This is lifetime membership and will not expire</div>
                                        <div className="contentHead">Forced Termination</div>
                                        <div className="contentNormal">On Any ANTI SSCSB Activities deemed by the Executive committee and ratified by the AGM / EGM</div>

                                        <div className="memberBenefitHead">Benefits</div>
                                        <div className="contentHead">Renewal Fee</div>
                                        <div className="contentNormal">Not Applicable</div>
                                        <div className="contentHead">Monthly Maintenance Fee </div>
                                        <div className="contentNormal">Zero. Not adopted yet. A nominal fee can be adopted in due course of time. </div>
                                        <div className="contentHead">Dependent Member </div>
                                        <div className="contentNormal">One member allowed as Associate Member</div>
                                        <div className="contentHead">Transfer Membership</div>
                                        <div className="contentNormal">Yes, can be transferred to spouse</div>
                                        <div className="contentHead">Vote in SSCSB Elections</div>
                                        <div className="contentNormal">Yes, Only One Vote</div>
                                        <div className="contentHead">Xorai Orihona</div>
                                        <div className="contentNormal">Free. One / Membership Year </div>
                                        <div className="contentHead">Any Special Waivers</div>
                                        <div className="contentNormal">Request should be made to General Secretary (GS) of SSCSB and subject to approval by GS</div>
                                        <div className="contentHead">Run for Office / Member of EC</div>
                                        <div className="contentNormal">Yes, should be a member for 6 months before nomination date</div>
                                        <div className="contentHead">Nomination to SAG </div>
                                        <div className="contentNormal">Yes, Nomination to 'Straegy and Advisory Group (SAG) on Approval at AGM by majority and subject to availability of vacant seat</div>
                                        <div className="contentHead">Nomination to Sub Committee</div>
                                        <div className="contentNormal">Yes</div>
                                        <div className="contentHead">Nominated Term in SAG </div>
                                        <div className="contentNormal">No restrictions on the number of terms of nominations subject to approval by majority at AGM & on vacant seat</div>
                                        <div className="contentHead">Upgrade</div>
                                        <div className="contentNormal">Doesn't Apply</div>
                                        <div className="contentHead">Naamghar Calendar</div>
                                        <div className="contentNormal">Yes</div>
                                        <div className="contentHead">Xasipaath </div>
                                        <div className="contentNormal">Yes</div>
                                        <div className="contentHead">Communication </div>
                                        <div className="contentNormal">Yes</div>
                                        <div className="contentHead">Invitation to Events</div>
                                        <div className="contentNormal">Yes</div>
                                        <div className="contentHead">Recognition </div>
                                        <div className="contentNormal">Display List at Namghar</div>
                                        <div className="contentHead">Certificate of Honor</div>
                                        <div className="contentNormal">Yes</div>
                                        <div className="contentHead">Welcome Kit</div>
                                        <div className="contentNormal">Hardbound / Physical & Digital </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </li>
                {/* Donor Member */}
                <li>
                    <div className='memberParentBlk'>
                        <div className='memberIconBlk'>
                            <div className='memIcon1'></div>
                        </div>
                        <div className='memberType'>
                            Donor Member
                            <div className='memberFee'>₹ 2,00,000 +</div>
                            <div className='memberBtnBlk'>
                                <input type='button' className='joinButton' value="Join Now" onClick={handleDonateClick} ></input>
                            </div>
                        </div>
                        <div className='memberTypeDesc'>
                            Donor membership to a person, who has made a significant 'one time' donation to the Society. Conferred based on the acceptance of the donation, and approval passed by the resoultion of EC and SAG.
                        </div>
                        {showFull && (
                            <>
                                <input
                                    type='button'
                                    className='expandButton'
                                    value={expandedBlock === 4 ? "Collapse" : "Expand"}
                                    onClick={() => toggleExpand(4)}
                                />

                                {expandedBlock === 4 && (
                                    <div className="memberBlkContent">
                                        <div className="contentHead">Eligibility Critiera</div>
                                        <div className="contentNote">(Above 18 years for all categories)</div>
                                        <div className="eligibilityBlk">
                                            <div className="eligibilityPoints">
                                                Any Nationality subject to Approval by Executive Committee (EC) and Strategy and Advisory Group (SAG)</div>
                                        </div>

                                        <div className="contentHead">Validation / Verification </div>
                                        <div className="contentNormal">Should have Valid ID proof</div>
                                        <div className="contentHead">Approval Process</div>
                                        <div className="contentNormal">Acceptance & approval by EC & SAG</div>
                                        <div className="contentHead">Duration / Membership Type </div>
                                        <div className="contentNormal">Lifetime</div>
                                        <div className="contentHead">Renewal </div>
                                        <div className="contentNormal">Auto Renewed</div>
                                        <div className="contentHead">Membership Fee</div>
                                        <div className="contentNormal">₹ 2,00,000 +</div>
                                        <div className="contentHead">Suspension / Validity</div>
                                        <div className="contentNormal">Not Applicable. This is lifetime membership and will not expire</div>
                                        <div className="contentHead">Forced Termination</div>
                                        <div className="contentNormal">On Any ANTI SSCSB Activities deemed by the Executive committee and ratified by the AGM / EGM</div>

                                        <div className="memberBenefitHead">Benefits</div>
                                        <div className="contentHead">Renewal Fee</div>
                                        <div className="contentNormal">Not Applicable</div>
                                        <div className="contentHead">Monthly Maintenance Fee </div>
                                        <div className="contentNormal">Zero. Not adopted yet. A nominal fee can be adopted in due course of time. </div>
                                        <div className="contentHead">Dependent Member </div>
                                        <div className="contentNormal">None</div>
                                        <div className="contentHead">Transfer Membership</div>
                                        <div className="contentNormal">No</div>
                                        <div className="contentHead">Vote in SSCSB Elections</div>
                                        <div className="contentNormal">No</div>
                                        <div className="contentHead">Xorai Orihona</div>
                                        <div className="contentNormal">Free. One / Membership Year </div>
                                        <div className="contentHead">Any Special Waivers</div>
                                        <div className="contentNormal">Request should be made to General Secretary (GS) of SSCSB and subject to approval by GS</div>
                                        <div className="contentHead">Run for Office / Member of EC</div>
                                        <div className="contentNormal">None</div>
                                        <div className="contentHead">Nomination to SAG </div>
                                        <div className="contentNormal">Yes, Nomination to 'Straegy and Advisory Group (SAG) on Approval at AGM by majority and subject to availability of vacant seat</div>
                                        <div className="contentHead">Nomination to Sub Committee</div>
                                        <div className="contentNormal">No</div>
                                        <div className="contentHead">Nominated Term in SAG </div>
                                        <div className="contentNormal">No restrictions on the number of terms of nominations subject to approval by majority at AGM & on vacant seat</div>
                                        <div className="contentHead">Upgrade</div>
                                        <div className="contentNormal">Doesn't Apply</div>
                                        <div className="contentHead">Naamghar Calendar</div>
                                        <div className="contentNormal">Yes</div>
                                        <div className="contentHead">Xasipaath </div>
                                        <div className="contentNormal">Yes</div>
                                        <div className="contentHead">Communication </div>
                                        <div className="contentNormal">Yes</div>
                                        <div className="contentHead">Invitation to Events</div>
                                        <div className="contentNormal">Yes</div>
                                        <div className="contentHead">Recognition </div>
                                        <div className="contentNormal">Display List at Namghar</div>
                                        <div className="contentHead">Certificate of Honor</div>
                                        <div className="contentNormal">Yes</div>
                                        <div className="contentHead">Welcome Kit</div>
                                        <div className="contentNormal">Hardbound / Physical & Digital </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </li>
            </ul>
        </div >
    );
};

export default MemberType;