import React from 'react';
import CustomButton from '../../component/CustomButton';

const XoraiForm = () => {

    return (
        <div className="contactFormContainer">
            <ul className='contactFormIIcol'>
                <li>
                    <label htmlFor="name">Name:</label>
                    <div className="userIcon"></div>
                    <input
                        type="text"
                        id="name"
                    />
                </li>
                <li>
                    <label htmlFor="name">Email:</label>
                    <div className="mailIcon"></div>
                    <input
                        type="text"
                        id="email"
                    />
                </li>
                <li>
                    <label htmlFor="name">Phone:</label>
                    <div className="phIcon"></div>
                    <input
                        type="text"
                        id="phone"
                    />
                </li>
                <li>
                    <label htmlFor="name">Subject:</label>
                    <div className="subjectIcon"></div>
                    <input
                        type="text"
                        id="subject"
                    />
                </li>
                <li className='singleRow'>
                    <label htmlFor="name">Message:</label>
                    <div className="msgIcon"></div>
                    <textarea></textarea>
                </li>
                <div className='buttonLabel'>
                    <CustomButton type="submit" value="Submit" css="btnRed_M" />
                </div>
            </ul>

        </div >
    );
};

export default XoraiForm;