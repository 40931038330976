import React, { useState } from 'react';
import { CalendarXorai, XoraiForm } from "../../components";
import Container from "../../container";
import { Link } from 'react-router-dom';

const Component = () => {

    const [date, setDate] = useState(new Date());

    const OnDateChange = (dt) => {
        setDate(dt);
    }

    return (
        <>
            <Container headerType={'inner'}>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div className='inPgHeadBlueBg'>
                            <div className="widthfix">
                                <ul className='breadcrumbLink'>
                                    <li className="breadcrumbLink">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumbLink">
                                        Offer Xorai
                                    </li>
                                </ul>
                                <div className='padBot60'></div>
                            </div>
                        </div>
                        <div className="widthfix">
                            <div className='offerXorai'>
                                <ul className='xoraiIIcol'>
                                    <li>
                                        <div className="pgHeadText">Select a Date to Offer Xorai</div>
                                        <CalendarXorai date={date} OnDateChange={OnDateChange} />
                                    </li>
                                    <li>
                                        <div className="pgHeadText">Offering Xorai on - {date.toDateString()}</div>
                                        <div className='signInLink'>Please <Link to="#" onClick={() => window.fnSign(1)}>SignIn</Link> if you a registered member</div>
                                        <XoraiForm />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;