import { React, Link } from "../../../common";
const Component = () => {
    return (
        <div id="home_bottom_blk">
            <div className="widthfix">
                <ul className="home_bot_ver_col">
                    <li className="home_bot_ver_col">
                        <Link to="/xorai">
                            <ul className="iconTxtBlk">
                                <li className="iconBlk">
                                    <div className="botIcon6"></div>
                                </li>
                                <li className="txtBlkXorai">Offer Xorai at Naamghar</li>
                                <li className="txtBlkXoraiShort">Offering Xorai</li>
                            </ul>
                        </Link>
                    </li>
                    <li className="home_bot_ver_col">
                        <Link to="/eventcalendar">
                            <ul className="iconTxtBlk">
                                <li className="iconBlk">
                                    <div className="botIcon1"></div>
                                </li>
                                <li className="txtBlk">Upcoming Events</li>
                            </ul>
                        </Link>
                    </li>
                    <li className="home_bot_ver_col">
                        {/* <Link to="/library"> */}
                        <Link>
                            <ul className="iconTxtBlk">
                                <li className="iconBlk">
                                    <div className="botIcon3"></div>
                                </li>
                                <li className="txtBlk">Library &amp; Publications</li>
                            </ul>
                        </Link>
                    </li>
                    <li className="home_bot_ver_col">
                        <Link to="/membership">
                            <ul className="iconTxtBlk">
                                <li className="iconBlk">
                                    <div className="botIcon4"></div>
                                </li>
                                <li className="txtBlk">Membership &amp; Members List</li>
                            </ul>
                        </Link>
                    </li>
                    <li className="home_bot_ver_col">
                        <Link to="/about">
                            <ul className="iconTxtBlk">
                                <li className="iconBlk">
                                    <div className="botIcon5"></div>
                                </li>
                                <li className="txtBlk">Know More About SSCSB</li>
                            </ul>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Component;