import React from 'react';
import Container from "../../container";
import { Link } from 'react-router-dom';
import { ContactForm } from '../../components';

const Component = () => {



    return (
        <>
            <Container headerType={'inner'}>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div className='inPgHeadBlueBg'>
                            <div className="widthfix">
                                <ul className='breadcrumbLink'>
                                    <li className="breadcrumbLink">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumbLink">
                                        Contact Us
                                    </li>
                                </ul>
                                <div className='padBot60'></div>
                            </div>
                        </div>
                        <div className="widthfix">
                            <div className='contact'>
                                <ul className='contactIIcol'>
                                    <li className='contactIIcol'>
                                        <div className="pgHeadText">Get In Touch</div>
                                        <ContactForm />
                                    </li>
                                    <li className='contactIIcol'>
                                        <div className="pgHeadText">Contact Information</div>
                                        <ul className='contactInfo'>
                                            <li className='contactInfo'>
                                                <div className='addressIcon'></div>
                                            </li>
                                            <li className='contactInfo'><b>Registered Address:</b><br />Sreemanta Sankardeva Cultural Society, Bangalore<br />No 16, 2nd Main, Abbaiah Reddy Layout, Kaggadaspura, Bengaluru, Karnataka 560093</li>
                                            <li className='contactInfo'>
                                                <div className='addressIcon'></div>
                                            </li>
                                            <li className='contactInfo'><b>Naamghar Address:</b><br />Sreemanta Sankardeva Cultural Society<br />1st Cross St, Maruthi Nagar,
                                                Lakshminarayana Pura, Chennappa Layout,
                                                Chinnapanna Halli, Bengaluru,
                                                Karnataka 560037</li>
                                            <li className='contactInfo'>
                                                <div className='mailIcon'></div>
                                            </li>
                                            <li className='contactInfo'>sscsbangalore@gmail.com</li>
                                            <li className='contactInfo'>
                                                <div className='phIcon'></div>
                                            </li>
                                            <li className='contactInfo'>99001 06821<br />Everyday: 8AM - 6:30PM</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;