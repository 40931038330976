import React from 'react';

const AboutSSCSB = () => {
    return (
        <div className='padBot20'>
            <div className='aboutUs'>
                <div className='pgTxtBold'>The Journey of Sreemanta Sankardeva Cultural Society, Bangalore (SSCSB)</div>
                <div className='pgTxtNormal'>One must feel very fortunate and blessed to be part of a common society and able to celebrate their own cultural rituals with their community members while being miles away from their hometown. Many of us echoed this sentiment. Whether for higher education, career aspirations, to be with their children or other reasons, many of us have found our way to Bengaluru and made it our home. While some have been residing there for over decades, others have only recently begun their journey in the city. Despite being from various background or different walks of like, we are all united by a common thread of solidarity with Assam or our Axomi Aaai and bonded by a deep love for our culture.</div>
                <div className='pgTxtNormal'>For a long time, many of us aspired for a community hall of our own, particularly a Naamghar in Bengaluru, and the topic had been discussed for years in different forums. Indeed, this had been a long-felt need. Unfortunately, due to various challenges including matters related to land, those conceived ideas could not materialize for a long time.</div>
                <div className='pgTxtNormal'>However, with the collective efforts of a persistent and persuasive group of like-minded Bangalore based Assamese people, an initiative was conceived in the form of  "Sreemanta Sankardeva Cultural Society, Bengaluru" (SSCSB) was set up in 2015 to give wings and carry forward the hopes and aspirations of the Assamese diaspora in Bengaluru. </div>
                <div className='pgTxtBold'>Establishing SSCSB and Our Naamghar</div>
                <div className='pgTxtNormal'>In January 2016, we registered the SSCSB under the Society Registration Act of Karnataka, 1960, with the primary objective of building a Naamghar with a Cultural Centre to promote and showcase our rich Assamese culture and heritage. By the grace of the Almighty, and with the support of a local resident of Chinnapannahalli, Marathahalli, we managed to get a piece of land for building our Naamghar. Subsequently, we succeeded in building our Naamghar through a crowdfunded initiative, and the same was inaugurated with the Guru Ashana Pratistha ceremony on 15 July 2018.</div>
                <div className='pgTxtBold'>Who we are?</div>
                <div className='pgTxtNormal'>The SSCSB today is a vibrant and dedicated organization committed to the preservation and celebration of Assamese culture and heritage. Established to create a sense of community among Assamese people residing in Bangalore, the society actively engages its members through a variety of cultural and religious events, festivals, and educational programs apart from various philanthropic and charitable initiatives. By organizing traditional festivities, artistic performances, and social gatherings, the society fosters a deep connection to Assamese traditions while also promoting intercultural exchange. Our mission is to provide a supportive space where members in particular and the larger Assamese community in general, can celebrate their cultural identity, share their heritage with others, and build lasting connections within the broader community. With a focus on inclusivity and cultural enrichment, the Srimanta Shankardev Cultural Society serves as a beacon of Assamese culture in Bangalore, enriching the lives of its members and bridging cultural gaps.</div>
                <div className='pgTxtBold'>Our Mission</div>
                <ul className="bulletPoints_un">
                    <li className="bp_circleOutline"><b>Enhance Spiritual Awareness:</b>To offer opportunities for spiritual growth and understanding through the study and practice of Vaishnavism, guided by the principles and teachings of Srimanta Sankaradeva. In this context, we aspire to construct and establish a Naamghar (Assamese Prayer House) in Bangalore.</li>
                    <li className="bp_circleOutline"><b>Preserve and Promote Assamese Culture:</b> To actively preserve and promote Assamese cultural heritage, including traditions, rituals, art forms such as dance, music, drama, and conduct festivals, as inspired by the teachings of Srimanta Sankaradeva. We aspire to build a Centre of Cultural Excellence in Bangalore to complement our efforts to promote our culture.</li>
                    <li className="bp_circleOutline"><b>Foster Community Spirit:</b> To create a strong sense of community among members by organizing events, gatherings, and educational programs that reflect the values of unity, mutual respect, compassion and inclusiveness championed by Srimanta Sankaradeva.</li>
                    <li className="bp_circleOutline"><b>Support Educational Initiatives:</b> To provide educational resources and programs that promote the study of Assamese language, literature, and history, ensuring the transmission of cultural knowledge to future generations. </li>
                    <li className="bp_circleOutline"><b>Promote Social Welfare:</b> To engage in social welfare activities that support the well-being and development of the community, reflecting the compassionate and service-oriented ideals of Srimanta Sankaradeva.</li>
                    <li className="bp_circleOutline"><b>Encourage Artistic Expression:</b> To support and celebrate traditional Assamese arts, including dance, music, and drama, as a means of honouring the cultural legacy and spiritual teachings of Srimanta Sankaradeva.</li>
                    <li className="bp_circleOutline"><b>Promote Vaishnavite Philosophy:</b>To foster an understanding and appreciation of Vaishnavism by organizing discussions, lectures, paper presentations, research and study groups focused on the principles and teachings of Srimanta Sankaradeva, thereby ensuring their continued relevance and practice within the community and beyond.</li>
                </ul>
                <div className='pgTxtBold'>SSCSB Today</div>
                <div className='pgTxtNormal'>SSCSB has been the centre of community bonding for the Assamese people in Bangalore. The Naamghar has risen as the centre of all our traditional religious initiatives. In 2023, we had more than 350 Xorais being offered at Naamghar. The Bihu and Janmastami festivals are attended by more than 1000 of devotees and visitors.</div>
                <div className='pgTxtNormal'>Last year also saw Sattriya dance, Borgeet, and other cultural activities at the Naamghar premises. On the literary front, we publish the Xasipaat, an annual souvenir, featuring voluntary contributions from our members on various aspects of Assamese culture. Under the aegis of SSCSB, we have also conducted Bhaona(s) which was well received the whole of Bangalore cutting across all barriers. </div>
                <div className='pgTxtBold'>Looking Ahead</div>
                <div className='pgTxtNormal'>Today, we feel proud and privileged that our dream of having our own community place and a Naamghar has become a reality. We strive to recreate the same spiritual ambiance that prevails in a typical Naamghar in Assam. Through our limited capacities and the support and dedication of our community members and volunteers, we have successfully carried forward the rich culture and heritage passed down through generations. Although, we still have "Miles to go, before we sleep!".</div>
            </div>
        </div>
    );
};

export default AboutSSCSB;
