import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import Container from "../../../container";

const events = {
    event1: {
        name: 'Pratistha Diwas', photos:
            [
                'https://lh3.googleusercontent.com/p/AF1QipPyDwr9zOeMJm6D-vEE2vu9craX4J7rypR16-QJ=s1360-w1360-h1020',
                'https://lh3.googleusercontent.com/p/AF1QipM-F4Kvx6S-_IV3o89M2g1azc0ILCApkSLeRXUK=s1360-w1360-h1020',
                'https://lh3.googleusercontent.com/p/AF1QipN-0o5apKF9AyYNLaQpz1VRCB_wPcVr0ppobGYJ=s1360-w1360-h1020',

            ]
    },
    event2: {
        name: 'Bohag Bihu', photos:
            [
                'https://lh3.googleusercontent.com/p/AF1QipPn2LpAZHG_mfWAQ3mzMEg8_13Vx8ePLV5BahB9=s1360-w1360-h1020',
                'https://lh3.googleusercontent.com/p/AF1QipNlnHLwMjyjoZf8tykKlJl5fyhKkWZRc81Zmqzf=s1360-w1360-h1020',
            ]
    },

};

const EventPhotos = () => {

    const { videoEventKey } = useParams();
    const [selectedPhoto, setSelectedPhoto] = useState(null);

    const handlePhotoClick = (photo) => {
        setSelectedPhoto(photo);
    };

    const handleCloseModal = () => {
        setSelectedPhoto(null);
    };

    const event = events[videoEventKey];

    return (
        <>
            <Container headerType={'inner'}>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div className='inPgHeadBlueBg'>
                            <div className="widthfix">
                                <ul className='breadcrumbLink'>
                                    <li className="breadcrumbLink">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumbLink">
                                        <Link to="/galleryvideoevents">Video Gallery</Link>
                                    </li>
                                    <li className="breadcrumbLink">{event?.name}</li>
                                </ul>
                                <div className='padBot60'></div>
                            </div>
                        </div>
                        <div className="widthfix">
                            <ul className='pgHeadingWithBC'>
                                <li>{event?.name}</li>
                                <li>
                                    <div className="filter">
                                        &nbsp;
                                    </div>
                                </li>

                                <div className="galleryContainer">
                                    <div className="photo-grid">
                                        {event?.photos.map((photo, index) => (
                                            <div key={index} className="photo-item" onClick={() => handlePhotoClick(photo)}>
                                                <img src={photo} alt={`Event ${videoEventKey} Photo ${index + 1}`} className="photo-thumbnail" />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </ul>

                        </div>
                        <Modal
                            isOpen={!!selectedPhoto}
                            onRequestClose={handleCloseModal}
                            className="modal"
                            overlayClassName="overlay"
                        >
                            <button onClick={handleCloseModal} className="close-button">X</button>
                            {selectedPhoto && <img src={selectedPhoto} alt="Enlarged Photo" className="enlarged-photo" />}
                        </Modal>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default EventPhotos;