import React from 'react';

const MembershipTable = ({ headers, rows }) => {

    const columnStyles = [
        { width: '25%' },
        { width: '20%' },
        { width: '20%' },
        { width: '20%' },
        { width: '15%' }, // Adjusted width for action column
    ];

    const handleRenewClick = (name) => {

        console.log(`Renew button clicked`);
    };

    return (
        <div className="table-container">
            <table>
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <th key={index}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                                <td key={cellIndex}>{cell}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default MembershipTable;
