import { React, Link, useLocation } from "../../common";
import { useEffect, useState } from 'react';
import Authentication from "../auth";
import Navbar from "./Navbar";
import TopRight from "./TopRight";

// Offer Xorai
const mnu1 = [
  "/xorai"
];
// Events
const mnu2 = [
  "#"
];
// Gallery
const mnu3 = [
  "#"
];
// More
const mnu4 = [
  "#",
];

const HomeHeader = ({ loc, ...props }) => {

  return (
    <header className="header">
      <div className="widthfix">
        <div className="logo">
          <Link to="/">
            <div className="sscsbLogo logoPosition"></div>
          </Link>
        </div>
        <div className="logoText">
          <div className="logoLn1">Bangalore Naamghar</div>
          <div className="logoLn2">An initiative of Sreemanta Sankardeva Cultural Society</div>

          {/* <div className="logoLn2">A Socio-Cultural Assamese Society in Bangalore</div> */}
        </div>

        <div className="Nav_RightContentBlk">
          <TopRight />

          <div className="topnav">
            <Navbar />
          </div>
        </div>
      </div>
    </header>
  );
};

const InHeader = ({ loc, ...props }) => {
  return (
    <header className="headerInpage">
      <div className="widthfix">
        <div className="logo">
          <Link to="/">
            <div className="sscsbLogo logoPosition"></div>
          </Link>
        </div>
        <div className="logoText">
          <div className="logoLn1">Bangalore Naamghar</div>
          <div className="logoLn2">An initiative of Sreemanta Sankardeva Cultural Society</div>
        </div>
        <div className="Nav_RightContentBlk">
          <TopRight />

          <div className="topnav">
            <Navbar />
          </div>

        </div>
      </div>
    </header>
  );
};

const ResponsiveHeader = ({ loc, ...props }) => {
  return (
    <header className="headerResponsive" >
      <div className="navBlockResponsive">
        <div className='headerTopRight'>
          <TopRight />
        </div>

        <div className="topnav" id="myTopnav">
          <Link to="/">
            <div className="sscsbLogo logoPosition"></div>
          </Link>

          <Navbar />
          <Link
            to="#"
            className="icon"
            onClick={() => window.myFunction()}
          ></Link>
        </div>
      </div>
    </header>
  );
};

const Component = (props) => {
  const { type } = props;
  const loc = useLocation().pathname;
  const [isResponsive, setIsResponsive] = useState(window.innerWidth < 999);

  useEffect(() => {
    const handleResize = () => {
      setIsResponsive(window.innerWidth < 999);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isResponsive) {
    return (
      <>
        <ResponsiveHeader loc={loc} {...props} />
        <Authentication />
      </>
    );
  }

  return (
    <>
      {type === 'inner' ? (
        <InHeader loc={loc} {...props} />
      ) : (
        <HomeHeader loc={loc} {...props} />
      )}
      <Authentication />
    </>
  );
};

export default Component;