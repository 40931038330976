import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Container from "../../../container";

const events = {
    event1: { name: 'Pratistha Diwas', year: 2023, photo: 'https://lh3.googleusercontent.com/p/AF1QipO4fygtozUrpHaDbwjbj_6ZqlWF78yun9pVZT26=s1360-w1360-h1020' },
    event2: { name: 'Bohag Bihu', year: 2023, photo: 'https://lh3.googleusercontent.com/p/AF1QipNkea7FYkqXCIjjDPqtX9ZGwRFBLB9zTQ4wtCo7=s1360-w1360-h1020' },
    // event3: { name: 'Janmasthami', year: 2023, photo: 'https://lh3.googleusercontent.com/p/AF1QipOvkGKBH8utxgH7jM-Jt8gyQNMi1d_64x7h32tK=s1360-w1360-h1020' },
    // event4: { name: 'Pratistha Diwas', year: 2022, photo: 'https://lh3.googleusercontent.com/p/AF1QipO3EkIST_wL0EpXBzTbjKUQzD5_dQMIufZ0Y_L8=s1360-w1360-h1020' },
    // event5: { name: 'Bhaona', year: 2022, photo: 'https://lh3.googleusercontent.com/p/AF1QipO9vFwrvNIEpAZTAFb8-etKo7oN4O8QboxdgDX5=s1360-w1360-h1020' },
    // event6: { name: 'Kati Bihu', year: 2022, photo: 'https://lh3.googleusercontent.com/p/AF1QipNlnHLwMjyjoZf8tykKlJl5fyhKkWZRc81Zmqzf=s1360-w1360-h1020' },
    // event7: { name: 'Janmasthami', year: 2021, photo: 'https://lh3.googleusercontent.com/p/AF1QipN5-OY3y2nNsO4RExuJ6nQk6u7PvArLEAB_V7UX=s1360-w1360-h1020' },
    // event8: { name: 'Pratistha Diwas', year: 2021, photo: 'https://lh3.googleusercontent.com/p/AF1QipOI4JoXyR0N8B3gKzJr4Xwoi2Y7IwFPEwma31Tk=s1360-w1360-h1020' },
    // event9: { name: 'Rajohuwa Naam', year: 2021, photo: 'https://lh3.googleusercontent.com/p/AF1QipN_y_a4pD-W-_1cGCRJYABN3eepkHT_vVJvWYIF=s1360-w1360-h1020' },
};

const getYears = () => {
    const years = new Set(Object.values(events).map(event => event.year));
    return Array.from(years);
};

const getEventNames = () => {
    const names = new Set(Object.values(events).map(event => event.name));
    return Array.from(names);
};

const PhotoList = () => {

    const [selectedYear, setSelectedYear] = useState('All');
    const [selectedEventName, setSelectedEventName] = useState('All');

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    };

    const handleEventNameChange = (e) => {
        setSelectedEventName(e.target.value);
    };

    const filteredEvents = Object.entries(events).filter(([key, event]) => {
        const matchesYear = selectedYear === 'All' || event.year === parseInt(selectedYear);
        const matchesEventName = selectedEventName === 'All' || event.name === selectedEventName;
        return matchesYear && matchesEventName;
    });

    return (
        <>
            <Container headerType={'inner'}>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div className='inPgHeadBlueBg'>
                            <div className="widthfix">
                                <ul className='breadcrumbLink'>
                                    <li className="breadcrumbLink">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumbLink">
                                        <Link to="/galleryphotoevents">Photo Gallery</Link>
                                    </li>
                                </ul>
                                <div className='padBot60'></div>
                            </div>
                        </div>
                        {/* <div className='inPgHeadBlueBg'>
                            <div className="widthfix">
                                <div className='padBot60'></div>
                            </div>
                        </div> */}
                        <div className="widthfix">
                            <ul className='pgHeading'>
                                <li>Photo Gallery</li>
                                <li>
                                    <ul className="filter">
                                        <li>
                                            <div className='filterHead'>Filter by Year:</div>
                                            <div className='filterDate'>
                                                <select id="yearFilter" onChange={handleYearChange} value={selectedYear}>
                                                    <option value="All">All</option>
                                                    {getYears().map(year => (
                                                        <option key={year} value={year}>{year}</option>
                                                    ))}
                                                </select>
                                            </div>

                                        </li>
                                        <li>
                                            <div className='filterHead'>Filter by Name:</div>
                                            <div className='filterName'>
                                                <select id="eventNameFilter" onChange={handleEventNameChange} value={selectedEventName}>
                                                    <option value="All">All</option>
                                                    {getEventNames().map(name => (
                                                        <option key={name} value={name}>{name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </li>
                                    </ul>
                                </li>

                                <div className="galleryContainer">
                                    <div className="event-list">
                                        {filteredEvents.map(([photoEventKey, event]) => (
                                            <Link key={photoEventKey} to={`/photo-event/${photoEventKey}`} className="event-link">
                                                <div className="event-item">
                                                    <img src={event.photo} alt={event.name} className="event-photo" />
                                                    <div className="event-details">
                                                        {event.name} ({event.year})
                                                    </div>
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </ul>


                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default PhotoList;