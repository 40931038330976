import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Container from "../../../container";
import MembershipTabs from "../component/membershipTabs";
import MembershipTabContent from "../component/membershipTabContent";
import MemberData from '../component/memberData';

const Component = () => {
    const [activeTab, setActiveTab] = useState('All Member');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 799);

    const inPageTabs = [
        { name: 'All Member', label: 'All Member', content: 'All Member' },
        { name: 'Associate Member', label: 'Associate Member', content: 'Associate Member' },
        { name: 'Regular Member', label: 'Regular Member', content: 'Regular Member' },
        { name: 'Lifetime Member', label: 'Lifetime Member', content: 'Lifetime Member' },
        { name: 'Honorary Member', label: 'Honorary Member', content: 'Honorary Member' },
        { name: 'Donor Member', label: 'Donor Member', content: 'Donor Member' },
        { name: 'Community Member', label: 'Community Member', content: 'Community Member' },
    ];

    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    };

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 799);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <Container headerType={'inner'}>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div className='inPgHeadBlueBg'>
                            <div className="widthfix">
                                <ul className='breadcrumbLink'>
                                    <li className="breadcrumbLink">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumbLink">
                                        <Link to="/membership">Membership</Link>
                                    </li>
                                    <li className="breadcrumbLink">
                                        {activeTab}
                                    </li>
                                </ul>
                                {isMobile ? (
                                    <div className="dropdown-container">
                                        <select value={activeTab} onChange={(e) => handleTabChange(e.target.value)}>
                                            {inPageTabs.map((tab) => (
                                                <option key={tab.name} value={tab.name}>{tab.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                ) : (
                                    <div className="tabs-container">
                                        <MembershipTabs inPageTabs={inPageTabs} activeTab={activeTab} onTabChange={handleTabChange} />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="content-container">
                            <div className="widthfix">
                                <MembershipTabContent activeTab={activeTab} inPageTabs={inPageTabs} tableData={MemberData} />
                            </div>
                        </div>

                        <div className="widthfix">

                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
