import { React } from "../../../common";
import { MemberType, MemberTypeHead } from "../../components";

const Component = () => {
    return (

        <div className="membershipContainer">
            <div className='memberHeadBg'>
                <div className="widthfix">
                    <MemberTypeHead />
                </div>
            </div>
            <div className="widthfix">
                <MemberType />
            </div>
        </div>
    )
}

export default Component;