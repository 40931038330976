import React from 'react';
import { DonatePop } from '../components';

const RightTop = () => {
    const [isOpen2, setIsOpen2] = React.useState(false);

    const togglePopup2 = () => {
        setIsOpen2(!isOpen2);
    };

    return (
        <>
            <div className="topRight_btnLabel">
                <input type='button' className='login_button' onClick={() => window.fnSign(1)} title='SignIn / SignUp' />
                <input type="button" className="donate_button" value="Donate Now" onClick={togglePopup2} />
            </div>
            <DonatePop isOpen={isOpen2} onClose={togglePopup2} />
        </>
    );
};

export default RightTop;