import React from 'react';

const MemberTabs = ({ inPageTabs, activeTab, onTabChange }) => {
    return (
        <div className="inPageTabs">
            {inPageTabs.map(tab => (
                <button
                    key={tab.name}
                    className={`tab ${activeTab === tab.name ? 'active' : ''}`}
                    onClick={() => onTabChange(tab.name)}
                >
                    {tab.label}
                </button>
            ))}
        </div>
    );
};

export default MemberTabs;