import React from 'react';
import { AdminHeader } from '../../components';

const Component = () => {

    return (
        <>
            <div>
                <AdminHeader />
            </div>
        </>
    )
}

export default Component;